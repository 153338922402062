.Package{
  display:grid;
  grid-template-columns: repeat(8,1fr);
  grid-gap:20px;
}
.Package__img{
  border-radius: 5px;
}

@media screen and (max-width:639px){
  .Package{
    display:grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap:10px;
    font-size:12px;
  }
}
