.scroll {
	width:100%;
}
.scroll .desc{
	display:none;
	width:100%;
}
.scroll .wrapper{
	width:100%;
	display:block;
	position:relative;
	text-align: center;
}
.scroll img{
	width:100%;
	filter:grayscale(20%);
	object-fit: cover;
}
.scroll .wrapper h2{
	position:absolute;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
	margin:0;
	padding:10px 10%;
	border:3px solid #fff;
	background-color: rgba(0,0,0,0.2);
	color:#fff;
}
.scroll p{
	width:auto;
	padding:5%;
	display:none;
}
.scroll .toggle{
	margin:10% auto;
	width:50px;
	height:50px;
	position:relative;
	background-color:rgba(0,0,0,0.1);
	border-radius:50px;
	cursor:pointer;
}
.scroll .toggle span{
	width:20px;
	height:5px;
	background-color:#fff;
	display:block;
	position:absolute;
	left:50%;
	top:50%;
  transform:translate(-50%,-50%);
	transform-origin:center center;
}
.scroll .toggle span:first-child{
	transform:translate(-50%,-50%) rotate(90deg) ;
}
.scroll .scrollRight img{
	object-fit:cover;
	height:100%;
}
/* medium */
@media screen and (max-width:1047px){

.scroll .wrapper h2{
	position:absolute;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
	margin:0;
	padding:5px 5%;
	border:3px solid #fff;
	background-color: rgba(255,255,255,0.1);
	color:#fff;
}

}
