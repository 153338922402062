.print .container{
  width:80%;
  margin:50px auto;
  display:grid;
  grid-template-columns: repeat(4,1fr) ;
  grid-template-rows: repeat(6,1fr) ;
  height:800px;
  grid-template-areas:
  " present present info info "
  " present present info info "
  " present present info info "
  " note note note note "
  " note note note note "
  " . . . button"
  ;
  background-color:#F0F0F0;
  grid-gap: 50px;
  padding:50px;
  box-sizing: border-box;
}
.print .present{
  grid-area:present;
  border:3px dashed #E3E3E3;
  border-radius:20px;
  position: relative;
  overflow:hidden;
}
.print .present canvas{
  object-fit:cover;
}
.present__placeholder{
  position:absolute;
  left:50%;
  top:50%;
  transform: translate(-50%,-50%);
  width:100%;
  padding:30% 0;
}
.present__placeholder::before{
  content:'打开或拖曳文件'
}
.present__placeholder--clicked{
  position:absolute;
  right:0%;
  top:0%;
  width:auto;
  padding:0;
}
.present__placeholder--clicked::before{
  content:'';
  background-image:url('../img/openFolder.svg');
  background-repeat:no-repeat;
  width:50px;
  height:50px;
  margin:5px;
  display:block;
  background-position: center;
  background-size:contain;
}
.print .note{
  grid-area:note;
}
.print .info{
  grid-area:info;
  display:grid;
  grid-template-columns: 20% 80%;
  grid-template-rows:repeat(7, 1fr);
  grid-template-areas:
  'title title'
  'title__color color'
  'title__color color'
  'title__material material'
  'title__volume volume'
  'title__surfaceArea surfaceArea'
  'title__price price';
  text-align: left;
}
.print .info__title{
  grid-area:title;
}
.print .title__color{
  grid-area:title__color;
}
.print .title__material{
  grid-area:title__material;
}
.print .title__volume{
  grid-area:title__volume;
}
.print .title__surfaceArea{
  grid-area:title__surfaceArea;
}
.print .title__price{
  grid-area:title__price;
}
.print .color{
  grid-area:color;
}
.print .material{
  grid-area:material;
}
.print .material__child{
  width:30%;
  text-align:center;
  border:2px solid #000;
  margin-right:10px;
  display:inline-block;
}
.print .volume{
  grid-area:volume;
}
.print .surfaceArea{
  grid-area:surfaceArea;
}
.print .price{
  grid-area:price;
}
.print textarea{
  border:0;
}
.print .button{
  grid-area:button;
  height:50px;
  width:50%;
  justify-self:end;
}
