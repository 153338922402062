.Competition{
  display:flex;
  flex-wrap:wrap;
}
.Competition__competitionCard{
  width:40%;
  margin:5%;
}
@media screen and (max-width:768px) {
  .Competition__competitionCard{
    width:80%;
    margin:50px 10%;
  }
}
