.kit {
	width: 80%;
	margin: 50px auto;
	background-color: #fff;
	display: flex;
	flex-wrap: wrap;
	border-radius: 50px;
	/* box-shadow:0px 2px 15px 0 rgba(0,0,0,0.3); */
	transition: 0.3s;
	background-size: cover;
}

.kit:hover {
	/* box-shadow:2px 2px 15px 0 rgba(0,0,0,0.5); */
}

.kit .leftWrapper {
	box-sizing: border-box;
	width: 30%;
	text-align: left;
	padding-left: 5%;
	margin-top: 50px;
	position: relative;
	height: auto;
	align-self: center;
}

.kit .leftWrapper span {
	background-color: #fff;
	padding: 10px 10px;
	border-radius: 5px;
	font-weight: 700;
}

.kit .leftWrapper h1 {
	width: 100%;
	margin: 20px 0;
	color: #fff;
	font-size: 20px;
}

.kit .leftWrapper h2 {
	margin: 10px 0;
	color: #fff;
}

.kit .leftWrapper p {
	margin: 30px 0;
	color: #fff;
	line-height: 24px;
}

.kit .middleWrapper {
	position: relative;
	width: 30%;
	align-self: center;
}

.kit .rightWrapper {
	margin: 50px 5%;
	width: 30%;
	text-align: left;
	justify-content: center;
}

.kit .rightWrapper h5 {
	width: 80%;
	margin: 0;
	margin-bottom: 5%;
	color: #fff;
}

.kit .rightWrapper .modules {
	width: 80%;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 20px;
}

.kit .rightWrapper .modules div {
	width: calc(100%/3 - 6%);
	margin-right: 2%;
	margin-bottom: 2%;
}

.kit .rightWrapper .modules div img {
	border-radius: 5px;
}

.kit .rightWrapper .list {
	text-align: left;
	width: 100%;
	padding: 0;
	margin: 0;
	list-style-type: none;
	color: #fff;
}

.kit .rightWrapper .list li {
	margin-bottom: 10px;
}

.kit--fullKit {
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-areas: 'middleWrapper leftWrapper ' 'rightWrapper rightWrapper';
}

.kit--fullKit .leftWrapper {
	grid-area: leftWrapper;
	width: 90%;
}

.kit--fullKit .middleWrapper {
	grid-area: middleWrapper;
	width: 100%;
}

.kit--fullKit .rightWrapper {
	grid-area: rightWrapper;
	width: 100%;
	margin: 50px 0;
	margin-left: 5%;
}

.kit--fullKit .rightWrapper .modules {
	width: 90%;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 20px;
}

.kit--fullKit .rightWrapper .modules div {
	width: calc(100%/7 - 4%);
	margin-right: 2%;
	margin-bottom: 2%;
}

.kit--fullKit .rightWrapper .list {
	text-align: left;
	width: 100%;
	padding: 0;
	margin: 0;
	list-style-type: none;
	color: #fff;
}

.kit--fullKit .rightWrapper .list li {
	margin-bottom: 10px;
	width: 25%;
	display: inline-block;
}

.kit--demoBtn {
	margin-right: 5%;
}

@media screen and (max-width:1043px) {
	.kit {
		background-size: cover;
	}
	.kit .leftWrapper {
		padding-top: 5%;
		overflow: hidden;
		width: 50%;
	}
	.kit .leftWrapper h1 {
		width: 50%;
		margin: 0;
		margin-top: 10%;
		color: #fff;
		font-size: 18px;
	}
	.kit .leftWrapper h2 {
		margin: 5% 0;
		color: #fff;
	}
	.kit .leftWrapper img {
		top: 0%;
	}
	.kit .middleWrapper {
		width: 60%;
		margin: 0 auto;
	}
	.kit .rightWrapper {
		width: 40%;
		justify-content: flex-start;
	}
	.kit .rightWrapper h2 {
		font-size: 16px;
		line-height: 24px;
	}
	.kit .rightWrapper .list {
		font-size: 14px;
	}
	.kit .rightWrapper .modules {
		width: 100%;
	}
	.kit .rightWrapper .list li {
		margin-bottom: 5px;
	}
	.kit--fullKit .leftWrapper {
		grid-area: leftWrapper;
		width: 90%;
	}
	.kit--fullKit .middleWrapper {
		grid-area: middleWrapper;
		width: 100%;
	}
	.kit--fullKit .rightWrapper {
		grid-area: rightWrapper;
		width: 90%;
		margin: 50px 0;
		margin-left: 5%;
	}
}

@media screen and (max-width:639px) {
	.kit {
		background-size: cover;
	}
	.kit .leftWrapper {
		width: 80%;
		height: auto;
		margin: 0 10%;
	}
	.kit .leftWrapper span {
		background-color: #fff;
		padding: 5px 5px;
		border-radius: 5px;
		font-weight: 400;
		position: relative;
		top: 30%;
	}
	.kit .leftWrapper h1 {
		width: 50%;
		color: #fff;
		font-size: 18px;
		position: relative;
		top: 0;
	}
	.kit .leftWrapper h2 {
		width: 50%;
		color: #fff;
		font-size: 24px;
		position: relative;
		top: 0;
		z-index: 1;
	}
	.kit .leftWrapper p {
		margin: 0;
	}
	.kit .middleWrapper {
		width: 100%;
	}
	.kit .rightWrapper h2 {
		margin-top: 0;
		margin: 20px auto;
		width: 90%;
		font-size: 14px;
		text-align: left;
	}
	.kit .rightWrapper {
		width: 100%;
		text-align: center;
		padding-bottom: 0;
	}
	.kit .rightWrapper .modules {
		margin-left: 10%;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}
	.kit .rightWrapper .list {
		text-align: center;
		width: 100%;
		padding: 0;
		margin: 0;
		list-style-type: none;
		color: #fff;
	}
	.kit--fullKit {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-areas: 'middleWrapper' 'leftWrapper' 'rightWrapper';
	}
	.kit--fullKit .rightWrapper {
		width: 100%;
		margin: 50px 0;
		margin-left: 0%;
	}
	.kit--fullKit .rightWrapper .modules {
		width: 90%;
	}
	.kit--fullKit .rightWrapper .modules div {
		width: calc(100%/4 - 4%);
	}
	.kit--fullKit .rightWrapper .list li {
		width: 50%;
	}
}

#modal-video .uk-modal-dialog {
	transform: none;
}