.TakeOrder{
  display:grid;
  grid-template-columns: 30% 70%;
  padding:50px 10%;
}
.OrderInfo{
  align-self: center;
}
.ContactInfo{
  width:60%;
  height:350px;
  background-color:#fff;
  text-align: left;
  box-shadow:0px 2px 10px rgba(0,0,0,0.2);
  margin:100px auto;
}
.ContactInfo__collegeName{
  width:70%;
  margin:0 auto;
  margin-top:25px;
  padding:30px;
}
.ContactInfo__collegeAddress{
  width:70%;
  margin:10px auto;
  padding:10px;

}
.ContactInfo__title{
  text-align: left;
  margin:0;
  padding:5px 0;
  display:block;
}
.ContactInfo__input{
  display:list-item;
  margin:0 auto;
  padding:10px 0;
  width:100%;
  display:block;
  border:none;
  outline: none;
  border-bottom:2px solid #000;
}
.ContactInfo__register{
  margin-left:15%;
  margin-top:20px;
  display:inline-block;
}
.ContactInfo__button{
  float:right;
  background-color:#000;
  color:#fff;
  border:none;
  padding:5px 30px;
  margin-right:15%;
  font-size:16px;
  margin-top:20px;
}
.ContactInfo__prompt{
  position:absolute;
  color:red;
}
@media screen and (max-width:768px){
  .TakeOrder{
    display:grid;
    grid-template-columns: 100%;
    padding:50px 10%;
  }
  .ContactInfo{
    width:100%;
  }
}
