.UGCUserContent{
	margin:100px 0;
	display:flex;
	flex-wrap:wrap;
}
@media screen and (max-width:639px){
.UGCUserContent{
	margin:50px 0;
	display:flex;
	flex-wrap:wrap;
}

}
