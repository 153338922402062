.Reset{
  width:20%;
  margin:50px auto;
  text-align: center;
}
.Reset__title{
  text-align: left;
  margin:0;
  padding:5px 0;
  display:block;
}
.Reset__newPassword{
  display:list-item;
  margin:0 auto;
  padding:10px 0;
  width:100%;
  display:block;
  border:none;
  outline: none;
  border-bottom:2px solid #000;
}
.Reset__btn{
  margin:50px 0;
}
