.OrderItem{
  display:flex;
  justify-content: space-between;
  margin:10px;
}
.OrderItem__value{
  width:50px;
  text-align: center;
}
.OrderItem__remove{
  border:1px solid #000;
  opacity: 0.3;
  transition:0.3s;
  cursor:pointer;
}
.OrderItem__remove:hover{
  opacity:1;
}
