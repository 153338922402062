.explore{
	margin-top:100px;
	width:80%;
	margin-left:10%;
	position:relative;
}
.explore header{
	display:flex;
	flex-wrap:wrap;
	justify-content: space-around;
}
.explore .toggle{
	width:150px;
}
.explore .toggle img{
	border-radius:100%;
	cursor:pointer;
}
.explore .toggle .toggleActive{
	box-shadow:0px 0px 5px 5px #7CCBDC;
}
.explore .list{
	margin:100px 0;
	display:flex;
	flex-wrap:wrap;
}

/* medium */
@media screen and (max-width:1047px){

.explore .toggle{
	width:100px;
}

}

/* small */
@media screen and (max-width:639px){

.explore {
	margin-top:50px;
}
.explore .toggle {
	width:50px;
}
.explore .list{
	margin:50px 0;
	display:flex;
	flex-wrap:wrap;
}

}
