.UGCHeaderLists{
	margin-top:100px;
	width:80%;
	margin-left:10%;
}
.UGCHeaderLists header{
	display:flex;
	flex-wrap:wrap;
	justify-content: space-around;
}

/* small */
@media screen and (max-width:639px){

.UGCHeaderLists {
	margin-top:50px;
}
.UGCHeaderLists .list{
	margin:50px 0;
	display:flex;
	flex-wrap:wrap;
}

}
