.office{
	width:80%;
	margin-left:10%;
	margin-top:50px;
}
.office .desc{
	width:80%;
	margin:100px auto;
	line-height: 36px;
	text-align: left;
}
