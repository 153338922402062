body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@-webkit-keyframes fadeIn{
	0% {opacity:0};
	100% {opacity:1};
}
@keyframes fadeIn{
	0% {opacity:0};
	100% {opacity:1};
}
@-webkit-keyframes fadeOut{
	0% {opacity:1};
	100% {opacity:0};
}
@keyframes fadeOut{
	0% {opacity:1};
	100% {opacity:0};
}

.App {
  text-align: center;
}


.font{
	font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
}

.nav{
	position:absolute;
	top:0;
	left:0;
	z-index:99;
	width:100%;
	height:100px;
	color:#fff;
	display:-webkit-flex;
	display:flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}
.navDark{
	box-shadow:2px 0px 15px 0 rgba(0,0,0,0.1);
	color:#000;
}
.nav .logo {
	width:20%;
	margin-left:10%;
	vertical-align: middle;
	z-index:100;
}
.logo img{
	height:100%;
}
.navWrapper{
	width:50%;
	margin-right:5%;
}
.navList{
	list-style-type:none;
	display:-webkit-flex;
	display:flex;
	margin:0;
	padding:0;
	width:100%;
	-webkit-justify-content: center;
	        justify-content: center;
}
.navList li{
	height:auto;
	font-weight:bold;
	color:inherit;
	position: relative;
	padding:calc((100px - 24px)/2) 0;
	width:80%;
	margin:auto;
}
.nav .navList li:hover{
	box-shadow:2px 0px 15px 0 rgba(0,0,0,0.1);
	cursor:pointer;
}
.navDark .navList li:hover{
	box-shadow:none;
}
.navList li span{
	cursor:pointer;
}
.navList li span:hover{
	color:#CED5D8;
}
.navList a{
	text-decoration: none;
	color: inherit;
	-webkit-transition:0.3s;
	transition:0.3s;
	width:inherit;
}
.navList a:hover{
	color:#CED5D8;
	text-decoration: none;
}
.navDropdown{
	width:100%;
	margin:0;
	position:absolute;
	top:100%;
	left:0;
	padding:0;
	display:none;
	-webkit-transition:0.3s;
	transition:0.3s;
}
.navOpen{
	display:block;
	-webkit-animation:fadeIn 0.3s linear;
	        animation:fadeIn 0.3s linear;
}
.navDropdown ul{
	padding:0;
	width:100%;
}
.navDropdown ul li{
	margin:5px 0;
	display:block;
	width:100%;
	padding:10px 0;
	position:inherit;
	box-shadow:2px 0px 15px 0 rgba(0,0,0,0.1);
}
.navDark .navDropdown ul li:hover{
	box-shadow:2px 0px 15px 0 rgba(0,0,0,0.1);
}

/* medium */
@media screen and (max-width: 1047px) {

.navList li{
	width:80%;
}

}

/* small */
@media screen and (max-width: 639px) {
.nav{
	color:#000;
}
.nav--active{
	color:#000;
	position:fixed;
}
.nav .logo {
	width:40%;
}
.navWrapper{
	overflow: hidden;
	width:30%;
	float:right;
}
#nav{
	display:none;
	position:fixed;
	top:0;
	left:0;
	text-align:center;
	height:100vh;
	padding:0;
	padding-top:100px;
	z-index:99;
	background-color:#fff;
	width:100%;
	overflow:hidden;
}
#nav li{
	display:block;
	padding:10px 0;
	background-color:#2D3B3D;
	margin:1px 0;
	color:#fff;
	width:100%;
}
.nav .navList li:hover{
	box-shadow:none;
}
#hamburger{
	float:right;
	overflow: hidden;
	vertical-align: middle;
	margin:32.5px 20px;
	z-index:100;
	position:relative;
}
.navButton{
	width:30px;
	height:5px;
	margin:5px 0;
	background-color:#fff;
	display:block;
	border-radius: 2px;
	-webkit-transition: 0.3s;
	transition: 0.3s;
}
.navDark .navButton{
	width:30px;
	height:5px;
	margin:5px 0;
	background-color:#000;
	display:block;
	border-radius: 2px;
	-webkit-transition: 0.3s;
	transition: 0.3s;
}
.navButtonOpened:first-child{
	width:30px;
	height:5px;
	margin:5px 0;
	background-color:#000;
	display:block;
	border-radius: 2px;
	-webkit-transform: rotate(-45deg) translate(-4px,-4px);
	transform: rotate(-45deg) translate(-4px,-4px);
	-webkit-transform-origin: top right;
	transform-origin: top right;
	-webkit-transition: 0.3s;
	transition: 0.3s;
}
.navButtonOpened:nth-child(2){
	width:30px;
	height:5px;
	margin:5px 0;
	background-color:#000;
	display:block;
	border-radius: 2px;
	opacity:0;
	-webkit-transition: 0.3s;
	transition: 0.3s;
}
.navButtonOpened:last-child{
	width:30px;
	height:5px;
	margin:5px 0;
	background-color:#000;
	display:block;
	border-radius: 2px;
	-webkit-transform: rotate(45deg) translate(-4px,4px);
	transform: rotate(45deg) translate(-4px,4px);
	-webkit-transform-origin:bottom right;
	transform-origin:bottom right;
	-webkit-transition: 0.3s;
	transition: 0.3s;
}
.navDropdown{
	position:static;
}
.navDropdown ul{
	margin:0;
}
#nav .navDropdown ul li{
	border:0;
	box-shadow:none;
}

}


.sliderFullscreen .toggle{
	list-style-type:none;
	bottom:10%;
	left:10%;
	padding:0;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}
.sliderFullscreen .toggle li {
	padding-bottom:5px;
	width:50px;
}
.sliderFullscreen .toggle li a {
	color:#fff;
	padding-bottom:5px;
}
.sliderFullscreen .toggle li a:hover {
	text-decoration: none;
}
.sliderFullscreen .uk-active{
	border-bottom:2px solid #fff;
}
.sliderFullscreen .slide{
	position:relative;
	width:100%;
	height:100vh;
	cursor:default;
}
.sliderFullscreen .slide .content{
	position:absolute;
	top:60%;
	left:20%;
	z-index:1;
	width:25%;
}
.sliderFullscreen  .slide .content h1{
	width:100%;
	text-align:left;
	color:#fff;
	line-height:150%;
}
.sliderFullscreen .slide  .content h2{
	width:100%;
	text-align:left;
	margin:0 0 20px 0;
	color:#fff;
	line-height:150%;
}
.sliderFullscreen .slide .content button{
	float:left;
	padding:10px;
	color:#000;
	background-color:#fff;
	width:50%;
	border-radius:25px;
	border:0;
	font-size:20px;
	font-weight:bold;
	cursor:pointer;
}
/* medium */
@media screen and (max-width:1047px){
.sliderFullscreen .slide{
	position:relative;
	width:100%;
	height:100vh;
}
.sliderFullscreen .slide img{
	height:100%;
	object-fit:cover;
}
.sliderFullscreen .slide .content{
	position:absolute;
	bottom:30%;
	left:30%;
	z-index:1;
	width:50%;
}


}

/* small */
@media screen and (max-width:639px){
.sliderFullscreen .slide{
	position:relative;
	width:100%;
	height:100vh;
}
.sliderFullscreen .slide img{
	height:100%;
	object-fit:cover;
}
.sliderFullscreen .toggle{
	list-style-type:none;
	bottom:20%;
	left:50%;
	-webkit-transform:translateX(-50%);
	        transform:translateX(-50%);
	padding:0;
	margin-bottom:0;
}
.sliderFullscreen .slide .content{
	width:100%;
	left:50%;
	top:50%;
	text-align: center;
}
.sliderFullscreen .slide .content h1{
	width:100%;
	font-size:26px;
	text-align:center;
}
.sliderFullscreen .slide .content h2{
	text-align:center;
}
.sliderFullscreen .slide .content button{
	padding:10px;
	width:50%;
	font-size:16px;
	float:none;
}
}

.oneStepSolutionHeader{
	margin-top:100px;
}

.scroll {
	width:100%;
}
.scroll .desc{
	display:none;
	width:100%;
}
.scroll .wrapper{
	width:100%;
	display:block;
	position:relative;
	text-align: center;
}
.scroll img{
	width:100%;
	-webkit-filter:grayscale(20%);
	        filter:grayscale(20%);
	object-fit: cover;
}
.scroll .wrapper h2{
	position:absolute;
	top:50%;
	left:50%;
	-webkit-transform:translate(-50%,-50%);
	        transform:translate(-50%,-50%);
	margin:0;
	padding:10px 10%;
	border:3px solid #fff;
	background-color: rgba(0,0,0,0.2);
	color:#fff;
}
.scroll p{
	width:auto;
	padding:5%;
	display:none;
}
.scroll .toggle{
	margin:10% auto;
	width:50px;
	height:50px;
	position:relative;
	background-color:rgba(0,0,0,0.1);
	border-radius:50px;
	cursor:pointer;
}
.scroll .toggle span{
	width:20px;
	height:5px;
	background-color:#fff;
	display:block;
	position:absolute;
	left:50%;
	top:50%;
  -webkit-transform:translate(-50%,-50%);
          transform:translate(-50%,-50%);
	-webkit-transform-origin:center center;
	        transform-origin:center center;
}
.scroll .toggle span:first-child{
	-webkit-transform:translate(-50%,-50%) rotate(90deg) ;
	        transform:translate(-50%,-50%) rotate(90deg) ;
}
.scroll .scrollRight img{
	object-fit:cover;
	height:100%;
}
/* medium */
@media screen and (max-width:1047px){

.scroll .wrapper h2{
	position:absolute;
	top:50%;
	left:50%;
	-webkit-transform:translate(-50%,-50%);
	        transform:translate(-50%,-50%);
	margin:0;
	padding:5px 5%;
	border:3px solid #fff;
	background-color: rgba(255,255,255,0.1);
	color:#fff;
}

}

.PCS{
	margin:100px 10%;
	display:-webkit-flex;
	display:flex;
	-webkit-flex-wrap:wrap;
	        flex-wrap:wrap;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}
.scroll{
	width:calc(90%/3);
	-webkit-transition:0.5s;
	transition:0.5s;
	display:-webkit-flex;
	display:flex;
	text-align: justify;
	margin-bottom:50px;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

/* small */
@media screen and (max-width:639px){
.scroll{
	width:100%;
	-webkit-transition:1s;
	transition:1s;
	display:-webkit-flex;
	display:flex;
	text-align: justify;
	margin-bottom:50px;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}
}

.partner {
	display:-webkit-flex;
	display:flex;
	-webkit-flex-wrap:wrap;
	        flex-wrap:wrap;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}
.partner header{
	width:80%;
	margin:0 10%;
}
.partner header img{
	width:10%;
	padding-bottom:50px;
}
.partner header span{
	display:inline-block;
	width:100%;
	font-size:24px;
	color:#333;
}
.partner .partnerBody{
	width:80%;
	margin:0 auto;
	display:-webkit-flex;
	display:flex;
	-webkit-flex-wrap:wrap;
	        flex-wrap:wrap;

}
.partner .partnerBody .partnerList{
	width:15%;
	margin:5%;
}
@media screen and (max-width:639px){
.partner header{
	width:100%;
	margin:0;
}
.partner header img{
	width:30%;
}
.partner .partnerBody .partnerList{
	width:40%;
	margin:10% 5%;
	margin-bottom:0;
}
}

footer {
    width: 100%;
    background-color: #333;
    margin: 0;
    margin-top: 100px;
    position: relative;
		overflow: hidden;
}
footer #contentInfo section{
	margin-left:10%;
}
footer #contentInfo ul{
	display:inline;
	color:#fff;
	width:25%;
	float:left;
	text-align:left;
	font-size:24px;
	padding:0;
	margin:50px 0;
}
footer #contentInfo section ul span{
	margin-bottom:15px;
	display:block;
}
footer #contentInfo section .footerLogo{
  width:20%;
}
footer #contentInfo ul li{
	color:#fff;
	list-style-type: none;
	text-align:left;
	font-size:14px;
	padding:10px 0;
}
footer #contentInfo section ul li a{
  color:#fff;
  text-decoration: none;
  -webkit-transition:0.3s;
  transition:0.3s;
}
footer #contentInfo section ul li a:hover{
  opacity:0.5;
}
footer #contentInfo ul li:first-child{
	margin-top:30px;
}
footer .author {
    padding:25px 0;
    text-align: center;
    color:#fff;
    width:100%;
    display:inline-block;
}
footer #author p{
  width:50%;
  margin:0 auto;
  text-align: center;
}
footer #social {
    width: 20%;
    position: absolute;
    bottom: 15%;
    right: 10%;
    cursor: pointer;
}

footer #social span {
    display: inline-block;
    width: 20%;
    margin: 0 10%;
    padding: 0;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    cursor: pointer;
}

footer #social span:hover {
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
}

footer .news:hover img{
	position:absolute;
	right:17%;
	top:24%;
	margin-left:-115px;
	padding-top:30px;
	display:block;
}
footer .news img{
	height:120px;
	display:none;
}
@media screen and (max-width:480px){
footer #contentInfo ul{
	display:inline;
	color:#fff;
	width:calc(100%/3);
	float:left;
	text-align:left;
	font-size:24px;
	padding:0;
	margin:50px 0;
	font-size:16px;
}
footer #contentInfo ul span{
	height:30px;
	display:block;
	text-align:left;
}
footer #contentInfo section:first-child ul span {
	text-align: center;
}
footer .footerLogo{
	position: absolute;
	left:50%;
	top:50%;
	-webkit-transform:translateX(-50%);
	        transform:translateX(-50%);
}
footer #contentInfo ul li{
	color:#fff;
	list-style-type: none;
	text-align:left;
	font-size:12px;
	padding:10px 0;
}
footer #contentInfo ul li:first-child{
	margin-top:0;
  padding:0;
}
footer #social {
    width: 50%;
    position: absolute;
    top: 60%;
    left: 50%;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
    cursor: pointer;
}
footer #author {
    padding: 25px 10%;
		margin-top:300px;
}

}


/*large*/
.title{
	width:200px;
	padding:15px 15px;
	text-align:center;
	margin:0 10%;
	margin-top:150px;
	font-size:21px;
	color:#fff;
	background-color:#5A7493;    /*#77A7B6*/
	box-shadow:0px 2px 15px 0 rgba(0,0,0,0.1);
	border-radius:2px;
}


/* medium */
@media screen and (max-width:1047px){


.title{
	width:200px;
	padding:15px 10px;
}


}

/* small */
@media screen and (max-width:689px){


.title{
	width:120px;
	padding:15px 10px;
}


}

.demo{
	width:80%;
	margin:50px auto;
	display:-webkit-flex;
	display:flex;
}
.demo .result{
	height:auto;
	width:calc(100%/4);
	background-color: #F0F0F0;
	border-right:1px solid #DBDBDB;
}
.demo .result header{
	background-color:#3E454C;
	color:#fff;
	padding:20px;
}
.demo .result p{
	padding:0 5%;
	text-align: left;
}
.demo .modules{
	width:75%;
	display:-webkit-flex;
	display:flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}
.demo .modules .module:nth-child(2n) header{
	color:#fff;
	background-color:#FF7F66;
	padding:20px;
}
.demo .modules .module:nth-child(2n+1) header{
	color:#fff;
	background-color:#7ECEFD;
	padding:20px;
}
.demo .modules .module{
	width:calc(100%/3);
	height:auto;
	background-color: #F0F0F0;
}
.demo .modules ul{
	display:-webkit-flex;
	display:flex;
	list-style-type:none;
	padding:0;
	margin:0;
	-webkit-flex-wrap:wrap;
	        flex-wrap:wrap;
}
.demo .modules ul li{
	width:calc(90%/4);
	padding:1.25%;
	cursor:pointer;
}
.demo .modules ul li img{
	border-radius:5px;
}
.demo .modules ul li span{
	font-size:12px;
}
/*medium*/
@media screen and (max-width:1047px){

.demo .modules ul li{
	width:calc(90%/3);
	padding:calc(10%/6);
	cursor:pointer;
}
.demo .modules .module{
	height:auto;
}
}

/*small*/
@media screen and (max-width:689px){

.demo {
	-webkit-flex-wrap:wrap;
	        flex-wrap:wrap;
}
.demo .result {
	width:100%;
	height:auto;
}
.demo .modules{
	width:100%;
}
.demo .modules .module{
	height:auto;
	width:calc(100%/2);
}
.demo .modules .module span{
	display:none;
}
.demo .modules ul li{
	width:calc(90%/2);
	padding:2.5%;
	cursor:pointer;
}


}

.button{
	background-color: #F3CE85;
	padding:10px 20px;
	border:0;
	border-radius:2px;
	color:#fff;
	box-shadow:2px 2px 5px 0 rgba(0,0,0,0.2);
	cursor:pointer;
}

.button--king{
	font-size:24px;
	padding:20px 40px;
}
.button--medium{
	font-size:18px;
	padding:10px 20px;
}
@media screen and (max-width:639px){
	.button--king{
		font-size:18px;
	}
}

#adjustCenter {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.selection img{
	height:100%;
}
.selectionContainer{
	display:grid;
	margin-left: 10%;
	width: 80%;
	grid-template-columns: repeat(4, 1fr);
	grid-auto-rows: minmax(60px, auto);
}
.block{
	color:#fff;
	padding:0 0;
}
.inputModuleBlock{
	grid-column:1 / 3;
	grid-template-rows:60px auto auto auto;
	display:grid;
}
.outputModuleBlock{
	grid-column:3 / 5;
	grid-template-rows:60px auto auto auto;
	display:grid;
}
.inputModuleBlock header{
	grid-row:1/2;
	grid-column:1;
	background-color:#FF7F66;
	padding:20px 0;
}
.outputModuleBlock header{
	grid-row:1/2;
	grid-column:1;
	background-color:#7ECEFD;
	padding:20px 0;
}
#moduleInput{
	grid-row:2/5;
	padding:50px;
	margin:0;
	background-color: #E5E5E5;
	display:grid;
	grid-template-columns: auto auto auto;
	grid-template-rows:1fr 1fr 1fr;
	grid-gap:30px;
	border-right:1px solid #DBDBDB;
	grid-template-areas:
	"i0 i1 i2"
	"i3 i4 i5"
	"i6 . .";
}
#moduleInput li{
	display:block;
	position:relative;
	list-style-type: none;
	font-size:12px;
	-webkit-transition:0.3s;
	transition:0.3s;
}
#moduleInput .i0{
	grid-area: i0;
}
#moduleInput .i1{
	grid-area: i1;
}
#moduleInput .i2{
	grid-area: i2;
}
#moduleInput .i3{
	grid-area: i3;
}
#moduleInput .i4{
	grid-area: i4;
}
#moduleInput .i5{
	grid-area: i5;
}
#moduleInput .i6{
	grid-area: i6;
}

#moduleInput li:hover{
	-webkit-transform: scale(1.1, 1.1);
	        transform: scale(1.1, 1.1);
	border-radius: 5px;
}
#moduleInput li img{
	border-radius: 5px;
	box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);
	-webkit-transition:0.3s;
	transition:0.3s;
	cursor:pointer;
}
#moduleInput li img:hover{
	box-shadow: 0px 7px 5px 0 rgba(0, 0, 0, 0.3);
}
#moduleInput li span{
	color:#000;
	position:absolute;
	width:100%;
	bottom:6%;
	left:0;
}
.active{
	/* box-shadow:0 0 5px 2px #E4517B; */
}
#moduleOutput{
	grid-row:2 / 5;
	padding:50px;
	margin:0;
	background-color: #E5E5E5;
	display:grid;
	grid-template-columns: auto auto auto;
	grid-template-rows:1fr 1fr 1fr;
	grid-gap:30px;
	grid-template-areas:
	"o0 o1 o2"
	"o3 o4 o5"
	". . .";
}
#moduleOutput li{
	display:block;
	position:relative;
	list-style-type: none;
	font-size:12px;
	-webkit-transition:0.3s;
	transition:0.3s;
}
#moduleOutput .o0{
	grid-area:o0 ;
}
#moduleOutput .o1{
	grid-area:o1;
}
#moduleOutput .o2{
	grid-area:o2;
}
#moduleOutput .o3{
	grid-area:o3;
}
#moduleOutput .o4{
	grid-area:o4;
}
#moduleOutput .o5{
	grid-area:o5;
}
#moduleOutput li:hover{
	-webkit-transform: scale(1.1, 1.1);
	        transform: scale(1.1, 1.1);
	border-radius: 5px;
}
#moduleOutput li img{
	border-radius: 5px;
	box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);
	-webkit-transition:0.3s;
	transition:0.3s;
	cursor:pointer;
}
#moduleOutput li img:hover{
	box-shadow: 0px 7px 5px 0 rgba(0, 0, 0, 0.3);
}
#moduleOutput li span{
	color:#000;
	position:absolute;
	width:100%;
	bottom:6%;
	left:0;
}
#reset {
	background-color: #000;
	color: #fff;
	border: none;
	cursor: pointer;
	padding: 10px 20px;
	float: right;
	margin-right: 10%;
	margin-top:-40px;
}

#reset:focus {
  outline: none;
}

.remove {
  position: absolute;
  top: 0;
  right: 0;
	z-index: 1;
	display: none;
	background-color: #000;
	color: #fff;
	border: none;
	opacity: 0.1;
	padding: 5px 10px;
	-webkit-transition: 0.3s;
	transition: 0.3s;
	cursor: pointer;
}

.remove:hover {
	opacity: 0.9;
	-webkit-transform: scale(1.1, 1.1);
	transform: scale(1.1, 1.1);
}
@media screen and (max-width:1047px){
#moduleInput li span{
	top:100%;
	font-size:12px;
}
#moduleOutput li span{
	top:100%;
	font-size:12px;
}
}
@media screen and (max-width:639px){
#moduleInput{
	grid-row:2/5;
	padding:5px;
	margin:0;
	background-color: #E5E5E5;
	display:grid;
	grid-template-columns: auto auto auto;
	grid-template-rows:1fr 1fr 1fr;
	grid-gap:5px;
	border-right:1px solid #DBDBDB;
	grid-template-areas:
	"i0 i1 i2"
	"i3 i4 i5"
	"i6 . .";
}
#moduleInput li span{
	display:none;
}
#moduleOutput{
	grid-row:2 / 5;
	padding:5px;
	margin:0;
	background-color: #E5E5E5;
	display:grid;
	grid-template-columns: auto auto auto;
	grid-template-rows:1fr 1fr 1fr;
	grid-gap:5px;
	grid-template-areas:
	"o0 o1 o2"
	"o3 o4 o5"
	". . .";
}
#moduleOutput li span{
	display:none;
}
#selection{
	overflow: hidden;
}
.wrapper{
	overflow:hidden;
}






}

#result{
	margin-top:50px;
	margin-left:10%;
	width:80%;
	color:#B6B7B6;
	font-size:20px;
	display:grid;
	grid-template-rows: 60px 100px 100px 150px 100px;
	grid-template-columns: repeat(10,1fr);
	grid-template-areas:
	"header header header header header header header header header header "
	"video video video video video content content content content content "
	"video video video video video content content content content content"
	"video video video video video content content content content content"
	"theme moduleIcon moduleIcon moduleIcon moduleIcon content content content content content"
}

#result header{
	background-color: #3E454C;
	padding:20px;
	color:#fff;
	grid-area: header;
}
#video{
	background-color:#E8E8E8;
	grid-area: video;
	position: relative;
}
#video iframe{
	position:absolute;
	z-index:1;
	top:0;
	left:0;
}
#video span{
	z-index:0;
	overflow: hidden;
}
#video span img{
	object-fit:cover;
}
#result .theme{
	grid-area:theme;
	background-color:#E3E3E3;
	padding:10px;
}
#result .moduleIcon{
	grid-area:moduleIcon;
	background-color:#F0F0F0;
	display:grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-rows: 100%;
	grid-template-areas:
	"mIcon iIcon oIcon cIcon";
}
#result .icon{
	background-color:#D6D5D5;
	margin-left:10%;
	margin-top:10%;
	margin-bottom:10%;
	border-radius: 2px;
}
#result .mIcon{
	grid-area: mIcon;
}
#result .iIcon{
	grid-area: iIcon;
}
#result .oIcon{
	grid-area: oIcon;
}
#result .cIcon{
	grid-area: cIcon;
}
#content{
	grid-area: content;
	display:grid;
	grid-template-columns: 1;
	grid-template-rows: 10% 10% 1fr 1fr 1fr;
	grid-template-areas:
	"."
	"h2"
	"p1"
	"p2"
	".";
	position: relative;
	background-color: #F0F0F0;
	color:#231F20;
}
#content #title{
	width:50%;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	list-style-type: none;
	color:#000;
	border:0;
	border-radius: 2px;
	font-size:24px;
	margin:0;
	padding:5px 0;
	padding-right:20px;
	background-color:#fff;
	cursor:pointer;
}
#content .option::after{
	position:absolute;
	right:50%;
	top:0;
	content:"\25BE";
	color:#000;
	display:block;
}
#content #title option{
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	font-size:14px;
}
#content h2{
	grid-area:h2;
	text-align:left;
	padding:0 50px;
	height:100%;
	align-self:end;
	margin:0;
	position:relative;
}
#content .p1{
	grid-area:p1;
	text-align:left;
	padding:0 50px;
}
#content .p2{
	grid-area:p2;
	text-align:left;
	padding:0 50px;
}
#content #placeholder{
	position:absolute;
	top:50%;
	left:50%;
	-webkit-transform: translate(-50%,-50%);
	transform: translate(-50%,-50%);
	z-index:0;
	color:#B6B7B6;
}





@media screen and (max-width:1047px){

#result{
		display: grid;
		grid-template-columns: repeat(10,1fr);
		grid-template-rows: 60px auto auto 100px 100px;
		grid-template-areas:
		"header header header header header header header header header header "
		"video video video video video video video video video video "
		"video video video video video video video video video video "
		"theme theme moduleIcon moduleIcon moduleIcon moduleIcon moduleIcon moduleIcon moduleIcon moduleIcon"
		"content content content content content content content content content content"
		"content content content content content content content content content content";
}

#content{
	grid-area: content;
	overflow-y: scroll;
	grid-template-columns: 1;
	grid-template-rows: auto 1fr auto;
	grid-template-areas:
	"h2"
	"p1"
	"p2";
	position: relative;
	background-color: #F0F0F0;
}
#result header{
	grid-area: header;
}
#video{
	grid-area: video;
}
#result .moduleIcon{
	grid-area:moduleIcon;
}
#result .theme{
	grid-area:theme;
}
#content #title{
	font-size:24px;
}
#content .option::after{
	right:50%;
	top:30%;
}
#content h2{
	padding-top:30px;
	-webkit-align-self:baseline;
	        align-self:baseline;
}
#content .p2{
	padding-bottom:50px;
}

}






@media screen and (max-width:639px){

#result{
	grid-template-rows: 60px 100px 100px 50px 50px;
}
header{
	font-size:14px;
}
#content{
	grid-area: content;
	display:grid;
	grid-template-columns: 1;
	grid-template-rows: auto 1fr auto;
	grid-template-areas:
	"h2"
	"p1"
	"p2";
	position: relative;
	background-color: #F0F0F0;
}
#content #title{
	width:60%;
	font-size:16px;
}
#content .option::after{
	right:40%;
	top:20%;
}
#content h2{
	padding:10px 10px;
	font-size:16px;
	-webkit-align-self:baseline;
	        align-self:baseline;
}
#content .p1{
	padding:0 10px;
	font-size:12px;
	margin:0;
}
#content .p2{
	padding:0 10px;
	font-size:12px;
	margin-bottom:50px;
}
#content span{
	font-size:14px;
}

}

.kit {
	width: 80%;
	margin: 50px auto;
	background-color: #fff;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	border-radius: 50px;
	/* box-shadow:0px 2px 15px 0 rgba(0,0,0,0.3); */
	-webkit-transition: 0.3s;
	transition: 0.3s;
	background-size: cover;
}

.kit:hover {
	/* box-shadow:2px 2px 15px 0 rgba(0,0,0,0.5); */
}

.kit .leftWrapper {
	box-sizing: border-box;
	width: 30%;
	text-align: left;
	padding-left: 5%;
	margin-top: 50px;
	position: relative;
	height: auto;
	-webkit-align-self: center;
	        align-self: center;
}

.kit .leftWrapper span {
	background-color: #fff;
	padding: 10px 10px;
	border-radius: 5px;
	font-weight: 700;
}

.kit .leftWrapper h1 {
	width: 100%;
	margin: 20px 0;
	color: #fff;
	font-size: 20px;
}

.kit .leftWrapper h2 {
	margin: 10px 0;
	color: #fff;
}

.kit .leftWrapper p {
	margin: 30px 0;
	color: #fff;
	line-height: 24px;
}

.kit .middleWrapper {
	position: relative;
	width: 30%;
	-webkit-align-self: center;
	        align-self: center;
}

.kit .rightWrapper {
	margin: 50px 5%;
	width: 30%;
	text-align: left;
	-webkit-justify-content: center;
	        justify-content: center;
}

.kit .rightWrapper h5 {
	width: 80%;
	margin: 0;
	margin-bottom: 5%;
	color: #fff;
}

.kit .rightWrapper .modules {
	width: 80%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	margin-bottom: 20px;
}

.kit .rightWrapper .modules div {
	width: calc(100%/3 - 6%);
	margin-right: 2%;
	margin-bottom: 2%;
}

.kit .rightWrapper .modules div img {
	border-radius: 5px;
}

.kit .rightWrapper .list {
	text-align: left;
	width: 100%;
	padding: 0;
	margin: 0;
	list-style-type: none;
	color: #fff;
}

.kit .rightWrapper .list li {
	margin-bottom: 10px;
}

.kit--fullKit {
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-areas: 'middleWrapper leftWrapper ' 'rightWrapper rightWrapper';
}

.kit--fullKit .leftWrapper {
	grid-area: leftWrapper;
	width: 90%;
}

.kit--fullKit .middleWrapper {
	grid-area: middleWrapper;
	width: 100%;
}

.kit--fullKit .rightWrapper {
	grid-area: rightWrapper;
	width: 100%;
	margin: 50px 0;
	margin-left: 5%;
}

.kit--fullKit .rightWrapper .modules {
	width: 90%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	margin-bottom: 20px;
}

.kit--fullKit .rightWrapper .modules div {
	width: calc(100%/7 - 4%);
	margin-right: 2%;
	margin-bottom: 2%;
}

.kit--fullKit .rightWrapper .list {
	text-align: left;
	width: 100%;
	padding: 0;
	margin: 0;
	list-style-type: none;
	color: #fff;
}

.kit--fullKit .rightWrapper .list li {
	margin-bottom: 10px;
	width: 25%;
	display: inline-block;
}

.kit--demoBtn {
	margin-right: 5%;
}

@media screen and (max-width:1043px) {
	.kit {
		background-size: cover;
	}
	.kit .leftWrapper {
		padding-top: 5%;
		overflow: hidden;
		width: 50%;
	}
	.kit .leftWrapper h1 {
		width: 50%;
		margin: 0;
		margin-top: 10%;
		color: #fff;
		font-size: 18px;
	}
	.kit .leftWrapper h2 {
		margin: 5% 0;
		color: #fff;
	}
	.kit .leftWrapper img {
		top: 0%;
	}
	.kit .middleWrapper {
		width: 60%;
		margin: 0 auto;
	}
	.kit .rightWrapper {
		width: 40%;
		-webkit-justify-content: flex-start;
		        justify-content: flex-start;
	}
	.kit .rightWrapper h2 {
		font-size: 16px;
		line-height: 24px;
	}
	.kit .rightWrapper .list {
		font-size: 14px;
	}
	.kit .rightWrapper .modules {
		width: 100%;
	}
	.kit .rightWrapper .list li {
		margin-bottom: 5px;
	}
	.kit--fullKit .leftWrapper {
		grid-area: leftWrapper;
		width: 90%;
	}
	.kit--fullKit .middleWrapper {
		grid-area: middleWrapper;
		width: 100%;
	}
	.kit--fullKit .rightWrapper {
		grid-area: rightWrapper;
		width: 90%;
		margin: 50px 0;
		margin-left: 5%;
	}
}

@media screen and (max-width:639px) {
	.kit {
		background-size: cover;
	}
	.kit .leftWrapper {
		width: 80%;
		height: auto;
		margin: 0 10%;
	}
	.kit .leftWrapper span {
		background-color: #fff;
		padding: 5px 5px;
		border-radius: 5px;
		font-weight: 400;
		position: relative;
		top: 30%;
	}
	.kit .leftWrapper h1 {
		width: 50%;
		color: #fff;
		font-size: 18px;
		position: relative;
		top: 0;
	}
	.kit .leftWrapper h2 {
		width: 50%;
		color: #fff;
		font-size: 24px;
		position: relative;
		top: 0;
		z-index: 1;
	}
	.kit .leftWrapper p {
		margin: 0;
	}
	.kit .middleWrapper {
		width: 100%;
	}
	.kit .rightWrapper h2 {
		margin-top: 0;
		margin: 20px auto;
		width: 90%;
		font-size: 14px;
		text-align: left;
	}
	.kit .rightWrapper {
		width: 100%;
		text-align: center;
		padding-bottom: 0;
	}
	.kit .rightWrapper .modules {
		margin-left: 10%;
		width: 100%;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-wrap: wrap;
		        flex-wrap: wrap;
	}
	.kit .rightWrapper .list {
		text-align: center;
		width: 100%;
		padding: 0;
		margin: 0;
		list-style-type: none;
		color: #fff;
	}
	.kit--fullKit {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-areas: 'middleWrapper' 'leftWrapper' 'rightWrapper';
	}
	.kit--fullKit .rightWrapper {
		width: 100%;
		margin: 50px 0;
		margin-left: 0%;
	}
	.kit--fullKit .rightWrapper .modules {
		width: 90%;
	}
	.kit--fullKit .rightWrapper .modules div {
		width: calc(100%/4 - 4%);
	}
	.kit--fullKit .rightWrapper .list li {
		width: 50%;
	}
}

#modal-video .uk-modal-dialog {
	-webkit-transform: none;
	        transform: none;
}
.BasicKit{
  margin:50px auto;
  border-radius:50px;
  box-shadow:0px 2px 15px 0 rgba(0,0,0,0.3);
  width:80%;
  position: relative;
}
.BasicKit__quiryBtn{
  position: absolute;
  bottom:10%;
  left:30%;
}
@media screen and (max-width:1043px){
  .BasicKit{
    margin:50px auto 100px auto;
  }
  .BasicKit__quiryBtn{
    position: absolute;
    top:105%;
    left:50%;
    -webkit-transform:translate(-50%,-50%);
            transform:translate(-50%,-50%);
  }
}

/*large*/
.titleSub{
	width:10%;
	padding:10px 0;
	text-align:center;
	margin:0 10%;
	margin-top:150px;
	font-size:16px;
	color:#fff;
	background-color:#2D3B3D;
	box-shadow:0px 2px 15px 0 rgba(0,0,0,0.1);
	border-radius:2px;
}





/* medium */
@media screen and (max-width:1047px){


.titleSub{
	width:150px;
	padding:15px 5%;
}


}





/* small */
@media screen and (max-width:639px){


.titleSub{
	width:100px;
	padding:15px 5%;
}


}

.TabBar{
  width:80%;
  margin: 100px auto;
  display:-webkit-flex;
  display:flex;
  -webkit-flex-wrap:wrap;
          flex-wrap:wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.TabBar__link{
  display:block;
  width:20%;
}
.TabBar__tab{
  width:100%;
  padding:20px 10px;
  border-radius: 2px;
  border:none;
  background-color:#7ECEFD;
  color:#fff;
  font-size: 16px;
  cursor:pointer;
}
.TabBar__tab--active{
  background-color:#FF7F66;
}
.TabBar__tilt{
  border-radius:10px;
  overflow:hidden;
}
.TabBar__tilt--0{
  background-color: #FF7F66;
}
.TabBar__tilt--1{
  background-color: #7ECEFD;
}
.TabBar__tilt--2{
  background-color: #502868;
}
.TabBar__tilt--3{
  background-color: #57AF6D;
}

.TabBar__image{
  padding:20%;
  padding-bottom:0;
}
@media screen and (max-width:639px){
  .TabBar__image{
    padding:0;
  }
  .TabBar__link{
    display:block;
    width:calc(50% - 20px);
    margin:10px;
  }
}

.FancyBtn{
  border-radius: 2px;
  background-color: #5D7592;
  width:200px;
  margin:0 auto;
}
.FancyBtn__Icon{
  padding-top:20px;
  padding-bottom:20px;
  color:#fff;
  margin:0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform-origin:center;
          transform-origin:center;
}
.FancyBtn__Icon--active{
  color:#000;
  -webkit-transform:rotate(90deg);
          transform:rotate(90deg);
  -webkit-transform-origin:center;
          transform-origin:center;
  text-decoration: none;
  color:#cb5b3f;
}
a:hover{
  text-decoration: none;
}
.FancyBtn__Name{
  color:#fff;
  padding-bottom:20px;
  margin:0;
}

.curriculum .content{
	width:60%;
	margin:100px auto;
	text-align: left;
	font-size:16px;
	padding:5% 10%;
	color:#fff;
	background-color:#77A7B6;
	/* background-image:url('../img/curriculumBg.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 50% 50%; */
}
.curriculum__section{
	margin:100px 0;
}
.curriculum__content{
	width:80%;
	margin:50px auto;
}
/* medium */
@media screen and (max-width:1047px){

.curriculum .content{
	background-size: cover;
	font-size:14px;
	padding:5% 10%;
}

}

/* small */
@media screen and (max-width:639px){

.curriculum .content{
	background-image:none;
	font-size:14px;
	padding:10%;
	color:#fff;
	text-align:justify;
}

}

.create{
	display:-webkit-flex;
	display:flex;
	width:80%;
	margin-left:10%;
	-webkit-flex-wrap:wrap;
	        flex-wrap:wrap;
	margin-top:50px;
	margin-bottom:200px;
	/* justify-content: space-between; */
}
.create .Tilt{
	width:calc(80%/5);
	height:500px;
	margin:0 calc(20%/10);
	margin-top:50px;
}

/* .create .Tilt:nth-child(n+4){
	opacity:0.7;
} */
.create a img{
	border-radius: 20px;
	-webkit-transition:0.3s;
	transition:0.3s;
	box-shadow:0 2px 5px rgba(0, 0, 0, 0.5);
}
.create a img:hover{
	box-shadow:0px 5px 20px rgba(0, 0, 0, 0.7);
}
/* medium */
@media screen and (max-width:1047px){
.create .Tilt{
	width:calc(80%/3);
	height:calc(80%/3);

}
}

/* small */
@media screen and (max-width:639px){
.create .Tilt{
	width:calc(80%/2);
	height:calc(80%/2);

}
}

.CompetitionCard{
  width:100%;
  box-shadow: 2px 2px 10px rgba(0,0,0,0.3);
}

.Competition{
  display:-webkit-flex;
  display:flex;
  -webkit-flex-wrap:wrap;
          flex-wrap:wrap;
}
.Competition__competitionCard{
  width:40%;
  margin:5%;
}
@media screen and (max-width:768px) {
  .Competition__competitionCard{
    width:80%;
    margin:50px 10%;
  }
}

.Login{
  width:30%;
  height:500px;
  background-color:#fff;
  text-align: left;
  box-shadow:0px 2px 10px rgba(0,0,0,0.2);
  margin:100px auto;
  position:relative;
}
.Login__account{
  width:70%;
  margin:0 auto;
  margin-top:25px;
  padding:30px;
}
.Login__button{
  background-color:#000;
  color:#fff;
  border:none;
  padding:5px 30px;
  font-size:16px;
  cursor:pointer;
}
.Login__button--press{
  background-color:rgba(0, 0, 0, 0.3);
  color:#fff;
  border:none;
  padding:5px 30px;
  font-size:16px;
  cursor:pointer;
}
.Login__button--wrapper{
  float:right;
  position: relative;
  margin-right:15%;
  margin-top:50px;
  width:auto;
}
.Login__pwd{
  width:70%;
  margin:10px auto;
  padding:10px;
}
.Login__title{
  text-align: left;
  margin:0;
  padding:5px 0;
  display:block;
}
.Login__input{
  display:list-item;
  margin:0 auto;
  padding:10px 0;
  width:100%;
  display:block;
  border:none;
  outline: none;
  border-bottom:2px solid #000;
}
.Login__register{
  margin-left:15%;
  margin-top:50px;
  display:inline-block;
}
.Login__prompt{
  position:absolute;
  color:red;
}
.Login__forget{
  position:absolute;
  right:15%;
  top:230px;
}
.Login__resetEmail{
  width:100%;
  margin-bottom:50px;
  font-size: 24px;
  border: 0;
  border-bottom:3px solid #000;
}
.Login__resetEmail:focus{
  outline:0;
}
.Login__recaptcha{
  position:absolute;
  top:330px;
  left:50%;
  -webkit-transform:translateX(-50%);
          transform:translateX(-50%);
}
.Login__resetEmail--prompt{
  position:absolute;
  color:red;
  top:60%;
}
.Login__googleSignIn{
  border-top:1px solid #000;
  padding:25px 0;
  width:70%;
  margin:70px auto;
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content:center;
          justify-content:center;
}

#Login__modal--forget {
  padding-top: 300px;
}


@media screen and (max-width:768px){
  .Login{
    width:80%;
  }
}
@media screen and (max-width:350px){
  .Login{
    width:100%;
  }
}

.ShoppingCard{
  width:calc(50% - 50px);
  display:grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 200px 50px 25px auto auto;
  grid-template-areas:
  'img img img img'
  'title title title title'
  'subTitle subTitle subTitle subTitle'
  'modules modules others others'
  '. . button button';
  border-radius: 5px;
  margin-left:50px;
  margin-bottom:50px;
  color:#fff;
  grid-gap: 10px;
  padding:50px;
  box-sizing: border-box;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
}
.ShoppingCard__img{
  grid-area:img;
  object-fit:contain;
  width:100%;
}
.ShoppingCard__img img{
  object-fit:contain;
  height:100%;
}
.ShoppingCard__title{
  grid-area:title;
  text-align: left;
  align-self: center;
}
.ShoppingCard__subTitle{
  grid-area:subTitle;
  text-align: left;
}
.ShoppingCard__modules{
  grid-area:modules;
  /* align-self: center; */
  text-align:left;
}
.ShoppingCard__others{
  grid-area:others;
  /* align-self: center; */
  text-align:left;
}
.ShoppingCard__add{
  grid-area:button;
  align-self: center;
  justify-self: center;
  -webkit-transition:0.3s;
  transition:0.3s;
  width:60%;
  padding:20px 10px;
  opacity: 0.9;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
}
.ShoppingCard__add:hover{
  cursor:pointer;
  opacity:1;
}
.ShoppingCard__add:active{
  opacity:0.7;
}
.ShoppingCard__add:focus{
  outline:0;
}
@media screen and (max-width:1200px){
  .ShoppingCard{
    grid-template-areas:
    'img img img img'
    'title title title title'
    'subTitle subTitle subTitle subTitle'
    'modules modules others others'
    '. button button button';
  }
}
@media screen and (max-width:1043px){
  .ShoppingCard{
    width:calc(90%);
    margin:25px auto;
    padding:25px;
    grid-template-areas:
    'img img img img'
    'title title title title'
    'subTitle subTitle subTitle subTitle'
    'modules modules others others'
    'button button button button';
  }
  .ShoppingCard__add{
    justify-self: center;
    -webkit-transition:0.3s;
    transition:0.3s;
    width:80%;
    padding:10px;
    opacity: 0.9;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
  }
}

/* .ShoppingCard__add button{
  background-color:#222;
}
.ShoppingCard__add button:hover{
  background-color:#111;
}
.ShoppingCard__add button:active{
  background-color:#000;
} */

.OrderList{
  box-shadow:1px 1px 5px rgba(0, 0, 0, 0.2);
  padding:20px 20px;
  overflow-y:auto;
  height:300px;
}
.OrderList__amount{
  padding-top:5px;
  text-align: right;
}
.OrderList__btn{
  margin:20px 0;
}
.OrderList__toggle{
  display:none;
}
@media screen and (max-width:768px) {
  .OrderList{
    box-shadow:1px 1px 5px rgba(0, 0, 0, 0.2);
    padding:20px 20px;
    overflow-y:auto;
    height:300px;
    display:none;
    background-color:#fff;
    -webkit-animation-name: slide;
            animation-name: slide;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
  }
  .OrderList--expand{
    display:block;
  }
  @-webkit-keyframes slide{
    from { height:0}
    to{height:300px}
  }
  @keyframes slide{
    from { height:0}
    to{height:300px}
  }
  .OrderList__btn{
    margin:0;
    text-align: right;
  }
  .OrderList__amount{
    position: absolute;
    padding:0;
    bottom:10px;
    left:2%;
    text-align: left;
  }
  .OrderList__bar{
    background-color:#fff;
  }
  .OrderList__title{
    display:none;
  }
  .OrderList__toggle{
    box-shadow: -1px -1px 2px rgba(0, 0, 0, 0.1);
    display:block;
    position: absolute;
    top:-12px;
    left:50%;
    -webkit-transform:translate(-50%,-50%);
            transform:translate(-50%,-50%);
    width:50px;
    background-color:#fff;
    border-radius: 10px 10px 0 0;
    margin: 0 auto;
  }
  .OrderList__toggle:before{
    content:'\25B2';
  }
  .OrderList__toggle--open:before{
    content:'\25BC';
  }

}

.OrderItem{
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin:10px;
}
.OrderItem__value{
  width:50px;
  text-align: center;
}
.OrderItem__remove{
  border:1px solid #000;
  opacity: 0.3;
  -webkit-transition:0.3s;
  transition:0.3s;
  cursor:pointer;
}
.OrderItem__remove:hover{
  opacity:1;
}

.TakeOrder{
  display:grid;
  grid-template-columns: 30% 70%;
  padding:50px 10%;
}
.OrderInfo{
  -webkit-align-self: center;
          align-self: center;
}
.ContactInfo{
  width:60%;
  height:350px;
  background-color:#fff;
  text-align: left;
  box-shadow:0px 2px 10px rgba(0,0,0,0.2);
  margin:100px auto;
}
.ContactInfo__collegeName{
  width:70%;
  margin:0 auto;
  margin-top:25px;
  padding:30px;
}
.ContactInfo__collegeAddress{
  width:70%;
  margin:10px auto;
  padding:10px;

}
.ContactInfo__title{
  text-align: left;
  margin:0;
  padding:5px 0;
  display:block;
}
.ContactInfo__input{
  display:list-item;
  margin:0 auto;
  padding:10px 0;
  width:100%;
  display:block;
  border:none;
  outline: none;
  border-bottom:2px solid #000;
}
.ContactInfo__register{
  margin-left:15%;
  margin-top:20px;
  display:inline-block;
}
.ContactInfo__button{
  float:right;
  background-color:#000;
  color:#fff;
  border:none;
  padding:5px 30px;
  margin-right:15%;
  font-size:16px;
  margin-top:20px;
}
.ContactInfo__prompt{
  position:absolute;
  color:red;
}
@media screen and (max-width:768px){
  .TakeOrder{
    display:grid;
    grid-template-columns: 100%;
    padding:50px 10%;
  }
  .ContactInfo{
    width:100%;
  }
}

.InquiryForm{
  grid-column: 1/4;
  display:grid;
  grid-template-columns: 25% 25% 10% 35%;
  grid-template-areas:
  'brief brief brief .'
  'category category category .';
  margin:0 auto;
  width:80%;
}
.InquiryForm__brief{
  grid-area:brief;
  text-align: left;
  line-height: 34px;
}
.InquiryForm__category{
  grid-area:category;
  border: 1px solid black;
  border-collapse: collapse;
}
.InquiryForm__category tr td{
  border: 1px solid black;
  border-collapse: collapse;
  padding:5px;
}
.InquiryForm__form{
  box-shadow: 0px 1px 2px rgba(0,0,0,0.5);
  display:grid;
  grid-template-columns: 1fr;
  grid-template-rows:auto auto auto;
  grid-template-areas:
  'title'
  'body'
  'submit';
  position:absolute;
  right:10%;
  width:25%;
}
.InquiryForm__form--title{
  background-color: #5A7493;
  color:#fff;
  text-align: left;
  padding:5% 10%;
}
.InquiryForm__form--body{
  padding:10%;
}
.InquiryForm__form--submit{
  text-align: right;
  padding:0 10% 5% 0%;
}

.Order{
  display:grid;
  grid-template-columns: 35% 35% 30%;
  grid-template-areas:
  'title title title'
  'shoppingCard shoppingCard list';
  grid-row-gap: 50px;
}
.Order__title{
  grid-area:title;
}
.Order__shoppingCard{
  grid-area:shoppingCard;
  display:-webkit-flex;
  display:flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.Order__list{
  grid-area:list;
  padding:0 30px;
  position:fixed;
  width:25%;
  right:0;
  top:250px;
}
.Order__contact{
  grid-column: 1/4;
}
@media screen and (max-width:768px){
  .Order{
    grid-template-columns: 100%;
  }
  .Order__list{
    grid-area:list;
    padding:0;
    position:fixed;
    width:100%;
    right:0;
    top:inherit;
    bottom:0;
  }

}

.article{
	width:70%;
	margin:50px auto;
	margin-left:10%;
	text-align: left;
}
.article header{
	margin:50px auto;
}
.article header .tag{
	background-color:#2D3B3D;
	color:#fff;
	width:10%;
	padding:5px 5px;
	text-align:center;
	font-size:14px;
	border-radius:20px;
}
.article > p{
	line-height: 48px;
}
/* medium */
@media screen and (max-width:1047px){

.article header .tag{
	width:15%;
}

}

/* small */
@media screen and (max-width:639px){
.article{
	width:65%;
}
.article > p{
	line-height: 32px;
}
.article header .tag{
	width:30%;
}

}

.sidebar{
	width:20%;
	position:fixed;
	top:30%;
	right:0;
}
.sidebar ul h4{
	background-color: #2D3B3D;
	width:50%;
	color:#fff;
	margin:20px auto;
	padding:10px 0;
	border-radius: 2px;
}
.sidebar ul li{
	width:50%;
	margin:0 auto;
	padding:20px 0;
	cursor:pointer;
}
.sidebarList{
	list-style-type:none;
}
.sidebarListActive{
	list-style-type:disc;
}

/* medium */
@media screen and (max-width:1047px){

.sidebar ul h4{
	padding:10px 20px;
	width:60%;
	font-size:16px;
	margin:0 auto;
}
.sidebar ul li{
	width:80%;
	margin:0 auto;
	padding:20px 0;
	cursor:pointer;
}

}

/* small */
@media screen and (max-width:639px){
.sidebar{
	top:23%;
	right:0;
}
.sidebar ul{
	padding:0;
}
.sidebar ul h4{
	padding:10%;
	width:60%;
	font-size:16px;
	margin:0 auto;
}
.sidebar ul li{
	width:60%;
	margin:0 auto;
	padding:20px 0;
	cursor:pointer;
}

}

.Service__section{
  margin:50px 0;
}

.QueryItem{
  width:100%;
  margin:10px auto;
  display:grid;
  grid-template-columns: repeat(5,1fr);
  grid-template-areas:
  'orderNum orderNum orderNum status status'
  'title title quantity subTotal .'
  'college college . quotation quotation';
  box-shadow:2px 2px 10px rgba(0 ,0 ,0,0.1);
  border-radius: 10px;
}
.QueryItem__num{
  grid-area:orderNum;
  margin: 10px 20px;
  align-self: center;
  text-align: left;
}
.QueryItem__status{
  grid-area:status;
  margin:10px;
}
.QueryItem__college{
  grid-area:college;
  margin:10px;
  align-self:center;
}
.QueryItem__quotation{
  grid-area:quotation;
  margin:10px;
}
.QueryItem__list{
  grid-area:list;
  display:-webkit-flex;
  display:flex;
}
.QueryItem__title{
  grid-area:title;
}
.QueryItem__quantity{
  grid-area:quantity;
}
.QueryItem__subTotal{
  grid-area:subTotal;
}

.OrderQuery{
  width:80%;
  margin:50px auto;
}
.OrderQuery__table{
  width:100%;
  border-collapse: collapse;
}
.OrderQuery__cell{
  border:2px solid #000;
}
.OrderQuery__cell td{
 padding:15px;
}
@media screen and (max-width:768px){
  .OrderQuery{
    width:100%;
    margin:50px auto;
    font-size:12px;
  }
}

.Activation{
  margin:50px auto;
  height:300px;
}
.Activation__Desc{
  margin:50px 0;
}
.Activation__active{
  margin:50px 0;
}
.Activation__resend{
  margin:10px 0;
}

.Reset{
  width:20%;
  margin:50px auto;
  text-align: center;
}
.Reset__title{
  text-align: left;
  margin:0;
  padding:5px 0;
  display:block;
}
.Reset__newPassword{
  display:list-item;
  margin:0 auto;
  padding:10px 0;
  width:100%;
  display:block;
  border:none;
  outline: none;
  border-bottom:2px solid #000;
}
.Reset__btn{
  margin:50px 0;
}

.Success{
  width:100%;
  margin-top:250px;
  margin-bottom:250px;
}
.Success__Icon{
  width:200px;
  margin:0 auto;
  -webkit-animation-name: scale;
          animation-name: scale;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}
.Success__Content{
  margin-top:20px;
  font-size:40px;
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}
@-webkit-keyframes scale {
    0% {-webkit-transform:scale(0,0);transform:scale(0,0)}
    30% {-webkit-transform:scale(1.2,1.2);transform:scale(1.2,1.2)}
    50% {-webkit-transform:scale(1,1);transform:scale(1,1)}
    70% {tranform: scale(1.1,1.1)}
    100% {-webkit-transform:scale(1,1);transform:scale(1,1)}
}
@keyframes scale {
    0% {-webkit-transform:scale(0,0);transform:scale(0,0)}
    30% {-webkit-transform:scale(1.2,1.2);transform:scale(1.2,1.2)}
    50% {-webkit-transform:scale(1,1);transform:scale(1,1)}
    70% {tranform: scale(1.1,1.1)}
    100% {-webkit-transform:scale(1,1);transform:scale(1,1)}
}
@-webkit-keyframes fadeIn {
    0% {opacity: 0}
    100% {opacity:1)}
}
@keyframes fadeIn {
    0% {opacity: 0}
    100% {opacity:1)}
}

@media screen and (max-width:768px){
  .Success{
    margin-top:400px;
  }
}

.Workshop{
  display:-webkit-flex;
  display:flex;
  -webkit-flex-wrap:wrap;
          flex-wrap:wrap;
}
.Workshop__competitionCard{
  width:40%;
  margin:5%;
}
@media screen and (max-width:768px) {
  .Workshop__competitionCard{
    width:80%;
    margin:50px 10%;
  }
}

.downLoad{
    margin-top: 100px;
    background-color: #F0F0F0;
    padding-top: 50px;
    padding-bottom: 50px;
}
.downLoad p,h2{
    padding: 0;
    margin: 0;
}
.downLoad .titleName{
    margin: 80px 0 50px;
    font-weight: 600;
}
.downLoad .top{
    padding-top: 50px;
    padding-bottom: 60px;
}
.downLoad .top p{
    margin: 16px 0 12px;
}

.downLoad .down{
    width: 80%;
    margin: 0 auto;
    padding:2rem;
    background-color: #fff;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    box-shadow: 0 0 2px #E6EBEE;
    position: relative;
    margin-bottom: 40px;
    border-radius: 5px;
}
.downLoad .down .downLeft{
    margin-right: 50px;
}
.downLoad .down .downLeft img{
    width: 190px;
}
.downLoad .down .downRight{
    width: calc(100% - 290px);
    text-align: left;
}
.downLoad .down .downRight .downName{
    font-size: 2rem;
    color: #000;
    font-weight: 600;
}
.downLoad .down .downRight .content{
    font-size: 1rem;
    margin-top: 25px;
}
.downLoad .down .downRight .setting{
    margin-top: 50px;
    margin-bottom: 5px;
    font-size: 1rem;
    color: #000;
    font-weight: 600;
}
.downLoad .down .downRight .downing{
    position: absolute;
    right: 40px;
    bottom: 35px;
}
.downLoad .down .downRight button{
    border: none;
    outline: none;
    padding: 10px 30px;
    background-color: #1EAAFF;
    color: #fff;
    font-size: 16px;
    border-radius: 30px;
    box-shadow: 0 2px 10px #C8E6F1;
    cursor: pointer;
}

@media screen and (max-width:1075px){
    .down .downing{
        /* display: none; */
    }
    .downLoad .down .downLeft{
        /* width: 30%; */
    }
    .downLoad .down .downLeft img{
        display: block;
        width: 90%;
    }
    .downLoad .down .downRight button{
        border: none;
        outline: none;
        padding: 5px 10px;
        background-color: #1EAAFF;
        color: #fff;
        font-size: 0.7rem;
        border-radius: 30px;
        box-shadow: 0 2px 10px #C8E6F1;
        cursor: pointer;
    }
    .downLoad .down .downRight .downName{
        font-size: 1.5rem;
    }
    .downLoad .down .downRight .content{
        font-size: 0.8rem;
        margin-top: 5px;
    }
    .downLoad .down .downRight .setting{
        margin-top: 20px;
        margin-bottom: 5px;
        font-size: 0.8rem;
        color: #000;
        font-weight: 600;
    }
    .downLoad .down .downLeft{
        margin-right: 30px;
    }
    
}
@media screen and (max-width:890px){
    .downing{
        display: none;
    }
    .downLoad .down{
        padding: 1rem;
    }
    .downLoad .down .first{
        /* padding-top: 40px; */
    }

    .downLoad .down .downLeft img{
        display: block;
        width: 100px;
    }
    .downLoad .down .downLeft{
        margin-right: 1rem;
    }

    .downLoad .down .downRight{
        width: 65%;
        text-align: left;
    }
    .downLoad .down .downRight .downName{
        font-size: 1rem;
    }
    .downLoad .down .downRight .content{
        font-size: 0.8rem;
        margin-top: 5px;
    }
    .downLoad .down .downRight .setting{
        margin-top: 20px;
        margin-bottom: 5px;
        font-size: 0.8rem;
        color: #000;
        font-weight: 600;
    }
}

.office{
	width:80%;
	margin-left:10%;
	margin-top:50px;
}
.office .desc{
	width:80%;
	margin:100px auto;
	line-height: 36px;
	text-align: left;
}

.contact{
	width:80%;
	margin-left: 10%;
	margin-top:50px;
}
.contact .wrapper{
	padding:0;
	margin:0;
	display:-webkit-flex;
	display:flex;
	-webkit-flex-wrap:wrap;
	        flex-wrap:wrap;
}
.contact .wrapper div{
	text-align: left;
  width:50%;
	margin:50px 0;
}

@media screen and (max-width:640px){
.contact .wrapper div{
	text-align: left;
  width:100%;
	margin:50px 0;
}
}

.faq{
	width:100%;
	display:-webkit-flex;
	display:flex;
	margin-top:50px;
}
.faq .category{
	padding:50px 0;
	width:25%;
	background-color:#77A7B6;
}
.faq .category span{
	display:inline-block;
	width:100%;
	padding:25px 0;
}
.faq .faqList{
	width:75%;
	text-align: left;
	box-sizing: border-box;
	padding:50px 10%;
}
.faq .faqList .question{
	cursor:pointer;
}
.faq .faqList .question:before{
	content:'+';
	float:right;
}
.faq .faqList .question_active{
	color:#77A7B6;
	cursor:pointer;
}
.faq .faqList .question_active:after{
	content:'-';
	float:right;
}
.faq .faqList .answer{
	background-color:#EFEFEF;
	display:none;
}
.faq .faqList .answer_show{
	display:block;
	-webkit-animation:fadeIn 0.3s linear;
	        animation:fadeIn 0.3s linear;
	
}

.print .container{
  width:80%;
  margin:50px auto;
  display:grid;
  grid-template-columns: repeat(4,1fr) ;
  grid-template-rows: repeat(6,1fr) ;
  height:800px;
  grid-template-areas:
  " present present info info "
  " present present info info "
  " present present info info "
  " note note note note "
  " note note note note "
  " . . . button"
  ;
  background-color:#F0F0F0;
  grid-gap: 50px;
  padding:50px;
  box-sizing: border-box;
}
.print .present{
  grid-area:present;
  border:3px dashed #E3E3E3;
  border-radius:20px;
  position: relative;
  overflow:hidden;
}
.print .present canvas{
  object-fit:cover;
}
.present__placeholder{
  position:absolute;
  left:50%;
  top:50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
  width:100%;
  padding:30% 0;
}
.present__placeholder::before{
  content:'\6253\5F00\6216\62D6\66F3\6587\4EF6'
}
.present__placeholder--clicked{
  position:absolute;
  right:0%;
  top:0%;
  width:auto;
  padding:0;
}
.present__placeholder--clicked::before{
  content:'';
  background-image:url(/static/media/openFolder.cceef69d.svg);
  background-repeat:no-repeat;
  width:50px;
  height:50px;
  margin:5px;
  display:block;
  background-position: center;
  background-size:contain;
}
.print .note{
  grid-area:note;
}
.print .info{
  grid-area:info;
  display:grid;
  grid-template-columns: 20% 80%;
  grid-template-rows:repeat(7, 1fr);
  grid-template-areas:
  'title title'
  'title__color color'
  'title__color color'
  'title__material material'
  'title__volume volume'
  'title__surfaceArea surfaceArea'
  'title__price price';
  text-align: left;
}
.print .info__title{
  grid-area:title;
}
.print .title__color{
  grid-area:title__color;
}
.print .title__material{
  grid-area:title__material;
}
.print .title__volume{
  grid-area:title__volume;
}
.print .title__surfaceArea{
  grid-area:title__surfaceArea;
}
.print .title__price{
  grid-area:title__price;
}
.print .color{
  grid-area:color;
}
.print .material{
  grid-area:material;
}
.print .material__child{
  width:30%;
  text-align:center;
  border:2px solid #000;
  margin-right:10px;
  display:inline-block;
}
.print .volume{
  grid-area:volume;
}
.print .surfaceArea{
  grid-area:surfaceArea;
}
.print .price{
  grid-area:price;
}
.print textarea{
  border:0;
}
.print .button{
  grid-area:button;
  height:50px;
  width:50%;
  justify-self:end;
}

.material__table{
  margin:0 auto;
}
.material__table th,.material__table td{
 padding:15px;
}

.cardSmallFn{
	width:calc(90%/4);
	border-radius:15px;
	margin:0 1.25%;
	margin-bottom:2.5%;
}
.cardSmallFn img{
	border-radius:15px 15px 0 0;
}
.cardSmallFnTitle{
  padding-bottom:10px;
	font-size:26px;
	font-weight: 400;
}
.cardSmallFn .like{
	position:absolute;
	width:50px;
	height:50px;
	right:5%;
	bottom:1%;
	background-color: transparent;
	border:0;
	cursor:pointer;
}
.cardSmallFn .like:focus{
	outline:none;
}
.cardSmallFn .like span{
	position: absolute;
	top:50%;
}
/* medium */
@media screen and (max-width:1047px){

.cardSmallFn{
	width:calc(90%/3);
}

}
/* small */
@media screen and (max-width:639px){

.cardSmallFn{
	width:calc(90%/1);
	margin-bottom:10%;

}
.cardSmallFnTitle{
	font-size:20px;
	font-weight: 400;
}

}

.UGCHeaderLists{
	margin-top:100px;
	width:80%;
	margin-left:10%;
}
.UGCHeaderLists header{
	display:-webkit-flex;
	display:flex;
	-webkit-flex-wrap:wrap;
	        flex-wrap:wrap;
	-webkit-justify-content: space-around;
	        justify-content: space-around;
}

/* small */
@media screen and (max-width:639px){

.UGCHeaderLists {
	margin-top:50px;
}
.UGCHeaderLists .list{
	margin:50px 0;
	display:-webkit-flex;
	display:flex;
	-webkit-flex-wrap:wrap;
	        flex-wrap:wrap;
}

}

.UGCHeaderList{
	width:150px;
}
.UGCHeaderList img{
	border-radius:100%;
	cursor:pointer;
}
.UGCHeaderList .UGCHeaderList--active{
	box-shadow:0px 0px 5px 5px #7CCBDC;
}

/* medium */
@media screen and (max-width:1047px){

.UGCHeaderList{
	width:100px;
}

}

/* small */
@media screen and (max-width:639px){

.UGCHeaderList {
	width:50px;
}

}

.UGCUserContent{
	margin:100px 0;
	display:-webkit-flex;
	display:flex;
	-webkit-flex-wrap:wrap;
	        flex-wrap:wrap;
}
@media screen and (max-width:639px){
.UGCUserContent{
	margin:50px 0;
	display:-webkit-flex;
	display:flex;
	-webkit-flex-wrap:wrap;
	        flex-wrap:wrap;
}

}

.slider .content{
	position:absolute;
	top:50%;
	left:50%;
	z-index:1;
	width:30%;
	-webkit-transform: translate(-50%,-50%);
	        transform: translate(-50%,-50%);
}
.slider .content h1{
	width:100%;
	text-align:center;
	color:#fff;
	line-height:150%;
}
.slider .content button{
	padding:10px;
	color:#000;
	background-color:#fff;
	width:50%;
	border-radius:25px;
	border:0;
	font-size:20px;
	font-weight:bold;
	cursor:pointer;
}
/* medium */
@media screen and (max-width:1047px){

.slider .content{
	position:absolute;
	bottom:30%;
	left:10%;
	z-index:1;
	width:50%;
}

}

/* small */
@media screen and (max-width:639px){
.slider .content{
	width:70%;
	left:50%;
	top:50%;
	-webkit-transform:translate(-50%,-50%);
	        transform:translate(-50%,-50%);
}
.slider .content h1{
	width:100%;
	font-size:26px;
	text-align:center;
}
.slider .content button{
	padding:10px;
	width:50%;
	font-size:16px;
	float:none;
}
}

.explore{
	margin-top:100px;
	width:80%;
	margin-left:10%;
	position:relative;
}
.explore header{
	display:-webkit-flex;
	display:flex;
	-webkit-flex-wrap:wrap;
	        flex-wrap:wrap;
	-webkit-justify-content: space-around;
	        justify-content: space-around;
}
.explore .toggle{
	width:150px;
}
.explore .toggle img{
	border-radius:100%;
	cursor:pointer;
}
.explore .toggle .toggleActive{
	box-shadow:0px 0px 5px 5px #7CCBDC;
}
.explore .list{
	margin:100px 0;
	display:-webkit-flex;
	display:flex;
	-webkit-flex-wrap:wrap;
	        flex-wrap:wrap;
}

/* medium */
@media screen and (max-width:1047px){

.explore .toggle{
	width:100px;
}

}

/* small */
@media screen and (max-width:639px){

.explore {
	margin-top:50px;
}
.explore .toggle {
	width:50px;
}
.explore .list{
	margin:50px 0;
	display:-webkit-flex;
	display:flex;
	-webkit-flex-wrap:wrap;
	        flex-wrap:wrap;
}

}

.Printer__body{
  width:100%;
  text-align: left;
}
.Printer__img{
  width:30%;
  margin:10%;
}
.Printer__title{
  width:20%;
  display:inline-block;
  text-align: left;
  vertical-align: middle;
}
.Printer__content{
  /* width:20%; */
  display:inline-block;
  text-align: left;
  vertical-align: middle;
}
.Printer-list__item{
  list-style-type: none;
  margin:12px 0;
}
@media screen and (max-width: 768px ){
  .Printer__img{
    width:80%;
    margin:10%;
  }
  .Printer__title{
    margin-left:10%;
    width:30%;
    font-size:12px;
    display:inline-block;
    text-align: left;
    vertical-align: top;
  }
  .Printer__content{
    width:auto;
    display:inline-block;
    text-align: left;
    vertical-align: top;
    font-size:12px
  }
}

.Colors{
  padding:0;
  margin:0;
  width:80%;
}
.Colors__item{
  list-style-type: none;
  display:inline;
}

.Consumables{
  width:100%;
  text-align: left;
}
.Consumables__img{
  width:30%;
  margin:10%;
  vertical-align: top;
}
.Consumables__title{
  width:20%;
  display:inline-block;
  text-align: left;
  vertical-align: top;
  margin:5% 0;
}
.Consumables__content{
  width:30%;
  display:inline-block;
  text-align: left;
  vertical-align: top;
  margin:5% 0;

}
.Consumables-list__item{
  list-style-type: none;
  margin:12px 0;
}
@media screen and (max-width: 768px ){
  .Consumables__img{
    width:80%;
    margin:10%;
  }
  .Consumables__title{
    margin-left: 10%;
    width:30%;
    font-size:12px;
    display:inline-block;
    text-align: left;
    vertical-align: top;
  }
  .Consumables__content{
    width:60%;
    display:inline-block;
    text-align: left;
    vertical-align: top;
    font-size:12px
  }
}

.Package{
  display:grid;
  grid-template-columns: repeat(8,1fr);
  grid-gap:20px;
}
.Package__img{
  border-radius: 5px;
}

@media screen and (max-width:639px){
  .Package{
    display:grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap:10px;
    font-size:12px;
  }
}

.curriculumKit__content{
  background-color:#FF7F66;
  color:#fff;
  width:80%;
  margin:0 auto;
  display:grid;
  grid-template-columns: repeat(4 , 1fr);
  grid-template-rows: 150px 150px auto auto;
  grid-template-areas:
  "img img title cat"
  "img img desc desc"
  "title__outline title__outline title__outline title__outline"
  "outline outline outline outline"
  "title__package title__package title__package title__package"
  "package package package package"
  "title__software title__software title__software title__software"
  "software software software software"
  "button button button button";
  grid-gap:50px;
  padding:50px;
  box-sizing: border-box;
  background-image: url(/static/media/curriculumKit__bg.e5247435.png);
  background-size: contain;
  background-position: center;
}
.curriculumKit__img{
  grid-area:img;
  overflow:hidden;
  background-color: #fff;
  border-radius: 5px;
  align-self:center;
}
.curriculumKit__img img{
  height:300px;
  object-fit:contain;
  border-radius: 5px;
}
.curriculumKit__title{
  grid-area:title;
  justify-self:start;
  align-self:flex-end;
}
.curriculumKit__cat{
  grid-area:cat;
  justify-self:end;
  align-self: flex-end;
}
.curriculumKit__desc{
  grid-area:desc;
  text-align:left;
}
.curriculumKit__outline{
  grid-area:outline;
}

.curriculumKit__table{
  width:100%;
}
.curriculumKit__td{
  padding:20px 0;
  color:#2D3B3D;
}
.curriculumKit__td:nth-child(2n+1){
  background-color:rgba(255,255,255,0.8);
  width:70%;
}
.curriculumKit__td:nth-child(2n){
  background-color:#fff;
  width:30%;
}
.curriculumKit__package{
  grid-area:package;
}
.curriculumKit__software{
  grid-area:software;
  display:grid;
  grid-template-columns:repeat(4,1fr);
  grid-template-areas:
  "blockly blockly cad cad";
  grid-gap:50px;
}
.curriculumKit__button{
  grid-area:button;
  margin:100px;
}
.content__title{
  text-align:left;
  color:#fff;
}
.title__outline{
  grid-area:title__outline;
}
.title__package{
  grid-area:title__package;
}
.title__software{
  grid-area:title__software;
}
.software__blockly{
  grid-area:blockly;
}
.software__cad{
  grid-area:cad;
}
.content__bgColor--0{
  background-color:#FF7F66;
}
.content__bgColor--1{
  background-color:#56A6DC;
}
.content__bgColor--2{
  background-color:#502868;
}
.content__bgColor--3{
  background-color:#57AF6D;
}
@media screen and (max-width:639px){
  .curriculumKit__content{
    width:80%;
    grid-template-columns: repeat(4 , 1fr);
    grid-template-rows: 150px 150px auto auto;
    grid-template-areas:
    "img img img img"
    "img img img img"
    "title title cat cat"
    "desc desc desc desc"
    "title__outline title__outline title__outline title__outline"
    "outline outline outline outline"
    "title__package title__package title__package title__package"
    "package package package package"
    "title__software title__software title__software title__software"
    "software software software software"
    "button button button button";
    grid-gap:10px;
    padding:10px;
    box-sizing: border-box;
    background-image: url(/static/media/curriculumKit__bg.e5247435.png);
    background-size: contain;
    background-position: center;
  }
  .curriculumKit__button{
    grid-area:button;
    margin:100px 0;
  }
  .curriculumKit__software{
    grid-area:software;
    display:grid;
    grid-template-columns:repeat(4,1fr);
    grid-template-areas:
    "blockly blockly blockly blockly"
    "cad cad cad cad";
    grid-gap:50px;
  }
}

.Feedback__form{
  margin:200px;
  position: relative;
  z-index: 1;
}
.Feedback__intro{
  width:50%;
  text-align:left;
  margin:50px auto;
}
.Feedback__iframe{
  margin-top:100px;
}
.Form__input{
  border:2px solid #000;
}
@media screen and (max-width:639px){
  .Feedback__form{
    margin:0;
    margin-top:100px;
  }
  .Feedback__intro{
    width:80%;
    text-align:left;
    margin:50px auto;
  }
}


.Register{
  width:30%;
  height:500px;
  margin:100px auto;
  background-color:#fff;
  text-align: left;
  box-shadow:2px 2px 10px rgba(0,0,0,0.2);
  position:relative;
}
.Register__account{
  width:70%;
  margin:0 auto;
  margin-top:50px;
  padding:20px;
}
.Register__pwd{
  width:70%;
  margin:10px auto;
  padding:10px;
  position:relative;
}
.Register__college{
  width:40%;
  margin:10px 0;
  margin-left:13%;
  padding:10px;
  position:relative;
  display:inline-block;
}
.Register__identity{
  width:20%;
  margin:10px auto;
  margin-left:10%;
  padding:10px;
  position:relative;
  display:inline-block;
  vertical-align:top;
}
.Register__input--identity{
  font-size:16px;
}
.Register__title{
  text-align: left;
  margin:0;
  padding:5px 0;
  display:block;
}
.Register__input{
  display:list-item;
  margin:0 auto;
  padding:10px 0;
  width:100%;
  display:block;
  border:none;
  outline: none;
  border-bottom:2px solid #000;
  font-size:16px;
}
.Register__mask{
  background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAdRJREFUeNrsWNFtwyAQdbNAGYENygbNCB7BI2QEOoG7Ae0EyQZuJog7gbMB2SCl0qWykLG5A5xIvSe9r9YHj+Mel6sqBoPBYDAYjIfDU8HYEujjDHxIIcKxdnx1VMAlfDn2jkfHw70z+rv5veM1A43jdm0BjeOQSYDPE8Qvim1BAT67yCuKqhEBqa8Rog9w/3vvIF6Qcd4d3xwvObJgESfZgvAlR9sjr5tKrYXYxSxhsRpxSBaZyT+YwiJuUMjaaUqJuM7YpoC/bResdVdCjCH4/5SANpA5HVi3yylGE2xyqg05EcTXhLWb1MIeuwn1MKY2YQl7UH7BUYLohM2cMlyv23UVGwgQ4/2xHa9AuJWPI7FhbTczQWNwnhByD8ibkI+EDIzRI76dajmeifv4xDhNrPt0Cd9m2YMgdLY20JvFfCcnsktxTREqQJvBy5uZOKF2RucSQRUzBAJKcMNuRD3zvzanCKoYk+g6XQkRYzHDCmJMSRFjA8Cc1h6xkES6lMnxaGukk+mZx1ERfuvscr6eiuDzg1fsWEfsSnYLDbHBxB5AXa0AUWi2tcpMa27KYhJEDfDeJE1Jcg+xJQiTMAcONYvf0GD2JQbaDAaDwWD8W/wIMAAZJYF+b9CoLgAAAABJRU5ErkJggg==);
  width:20px;
  height:20px;
  border:0;
  background-size:contain;
  position:absolute;
  top:55%;
  left:80%;
}
.Register__mask:focus{
  outline:0;
}
.Register__unmask{
  background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAlFJREFUeNrsmdFtwjAQhkNZwFTi3eoCScUApUyQETICI2SDbECe6Us3IEL0mdBnVKXvCLkLoNSnJpVBTmJfnEBQTvoFkSLbn+278zmW1VtvvfXWm2FLTydyDxAhV9xpGIBgx2O6227TzsLkEM+2nT4S0k0YEeKBP4KeKAUQUNj2eCjXVCKqC9HmikDjHhfMVgzjUVDEFXC5twABg3hXHHipPjab+BoQMPuJCQBQuFikbUNMTQJcA4IgthC8P79wdF9spwwi5iL8f+ZHxNQqMA0AlY7p23LJFCByQQBx6vqCKgBT7awsOkkgxPZdDER4QxCivKYg0mz7FfnWv48YgNCC0YUICwACneg0Ho8jzX5LYXxECJUdOWJEiHURfXt1HVuMJqWToZknGGIMZ77pIBvxJSBMBeJrv2eSZBchxsDEcB8hM7MvOfHqJDsTW/vMTxmyAU+SPFUhTIJEeQOBoRUhGhBMAhKYmNC4zpIikl1YFe2w4Z8gTrYMCcEkYZsio6b0XIeJXh4CwjHgH3HV4VQLpswnjodDkmXsXH5B51RzAishtGAMFkVRExAiTNICRNgkhBgAooYgqGaUCk1Uir5qxh6NRix7n5astG6tMzdZtzua5WmabU3R2RkiY9PGrzGRRZGKEmxZeysQsW4pawQCHHsymbjZnk9qzH5Q95ZkUAEBHcxns5m1Xq//whgh1mq1smzb3vHH18Fw+CO5uIbfl4Jm4f1Prl2m7zau9x0uBrMPq9DZ7xSXMJ3+ciTCdP5bXgZD7+Lram+99dZt+xVgAHw3WljBStLHAAAAAElFTkSuQmCC);
  width:20px;
  height:20px;
  border:0;
  background-size:contain;
  position:absolute;
  top:55%;
  left:80%;
}
.Register__button{
  background-color:#000;
  color:#fff;
  border:none;
  padding:20px 0;
  font-size:16px;
  width:50%;
  position:absolute;
  top:400px;
  left:50%;
  -webkit-transform:translateX(-50%);
          transform:translateX(-50%);
}
.Register__button--press{
  background-color:rgba(0, 0, 0, 0.3);
  color:#fff;
  border:none;
  padding:20px 0;
  font-size:16px;
  width:50%;
  position:absolute;
  top:400px;
  left:50%;
  -webkit-transform:translateX(-50%);
          transform:translateX(-50%);
}
.Register__prompt{
  position:absolute;
  color:red;
}
.Register__recaptcha{
  position:absolute;
  top:350px;
  left:50%;
  -webkit-transform:translateX(-50%);
          transform:translateX(-50%);
}
@media screen and (max-width:768px){
  .Register{
    width:100%;
  }
}

.Profile__body{
  margin-top:50px;
  display:grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap:50px;
}
.Profile__avatar{
  grid-column-start: 1;
  grid-column-end:3;
  width:200px;
  padding:25px;
  border-radius: 200px;
  background-color: #d6e4ea;
  overflow: hidden;
  margin:0 auto;
  border:10px solid #b8bfc1;
}
.Profile__blank{
  margin:50px auto;
  text-align: left;
  width:40%;
}
.Profile__label{
  width:100%;
  display:block;
}
.Profile__input{
  border:0;
  border-bottom:2px solid #000;
  width:100%;
  font-size:21px;
}
.Profile__select{
  border:0;
  border-radius:0;
  border-bottom:2px solid #000;
  width:100%;
  font-size:21px;
}
.Profile__input:focus{
  outline:0;
}
.Profile__editPassword{
  width:50%;
}
.input__wrapper{
  margin:20px 0;
}

