.create{
	display:flex;
	width:80%;
	margin-left:10%;
	flex-wrap:wrap;
	margin-top:50px;
	margin-bottom:200px;
	/* justify-content: space-between; */
}
.create .Tilt{
	width:calc(80%/5);
	height:500px;
	margin:0 calc(20%/10);
	margin-top:50px;
}

/* .create .Tilt:nth-child(n+4){
	opacity:0.7;
} */
.create a img{
	border-radius: 20px;
	transition:0.3s;
	box-shadow:0 2px 5px rgba(0, 0, 0, 0.5);
}
.create a img:hover{
	box-shadow:0px 5px 20px rgba(0, 0, 0, 0.7);
}
/* medium */
@media screen and (max-width:1047px){
.create .Tilt{
	width:calc(80%/3);
	height:calc(80%/3);

}
}

/* small */
@media screen and (max-width:639px){
.create .Tilt{
	width:calc(80%/2);
	height:calc(80%/2);

}
}
