.Consumables{
  width:100%;
  text-align: left;
}
.Consumables__img{
  width:30%;
  margin:10%;
  vertical-align: top;
}
.Consumables__title{
  width:20%;
  display:inline-block;
  text-align: left;
  vertical-align: top;
  margin:5% 0;
}
.Consumables__content{
  width:30%;
  display:inline-block;
  text-align: left;
  vertical-align: top;
  margin:5% 0;

}
.Consumables-list__item{
  list-style-type: none;
  margin:12px 0;
}
@media screen and (max-width: 768px ){
  .Consumables__img{
    width:80%;
    margin:10%;
  }
  .Consumables__title{
    margin-left: 10%;
    width:30%;
    font-size:12px;
    display:inline-block;
    text-align: left;
    vertical-align: top;
  }
  .Consumables__content{
    width:60%;
    display:inline-block;
    text-align: left;
    vertical-align: top;
    font-size:12px
  }
}
