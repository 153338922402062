.InquiryForm{
  grid-column: 1/4;
  display:grid;
  grid-template-columns: 25% 25% 10% 35%;
  grid-template-areas:
  'brief brief brief .'
  'category category category .';
  margin:0 auto;
  width:80%;
}
.InquiryForm__brief{
  grid-area:brief;
  text-align: left;
  line-height: 34px;
}
.InquiryForm__category{
  grid-area:category;
  border: 1px solid black;
  border-collapse: collapse;
}
.InquiryForm__category tr td{
  border: 1px solid black;
  border-collapse: collapse;
  padding:5px;
}
.InquiryForm__form{
  box-shadow: 0px 1px 2px rgba(0,0,0,0.5);
  display:grid;
  grid-template-columns: 1fr;
  grid-template-rows:auto auto auto;
  grid-template-areas:
  'title'
  'body'
  'submit';
  position:absolute;
  right:10%;
  width:25%;
}
.InquiryForm__form--title{
  background-color: #5A7493;
  color:#fff;
  text-align: left;
  padding:5% 10%;
}
.InquiryForm__form--body{
  padding:10%;
}
.InquiryForm__form--submit{
  text-align: right;
  padding:0 10% 5% 0%;
}
