.FancyBtn{
  border-radius: 2px;
  background-color: #5D7592;
  width:200px;
  margin:0 auto;
}
.FancyBtn__Icon{
  padding-top:20px;
  padding-bottom:20px;
  color:#fff;
  margin:0;
  transition: 0.3s;
  transform-origin:center;
}
.FancyBtn__Icon--active{
  color:#000;
  transform:rotate(90deg);
  transform-origin:center;
  text-decoration: none;
  color:#cb5b3f;
}
a:hover{
  text-decoration: none;
}
.FancyBtn__Name{
  color:#fff;
  padding-bottom:20px;
  margin:0;
}
