#adjustCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.selection img{
	height:100%;
}
.selectionContainer{
	display:grid;
	margin-left: 10%;
	width: 80%;
	grid-template-columns: repeat(4, 1fr);
	grid-auto-rows: minmax(60px, auto);
}
.block{
	color:#fff;
	padding:0 0;
}
.inputModuleBlock{
	grid-column:1 / 3;
	grid-template-rows:60px auto auto auto;
	display:grid;
}
.outputModuleBlock{
	grid-column:3 / 5;
	grid-template-rows:60px auto auto auto;
	display:grid;
}
.inputModuleBlock header{
	grid-row:1/2;
	grid-column:1;
	background-color:#FF7F66;
	padding:20px 0;
}
.outputModuleBlock header{
	grid-row:1/2;
	grid-column:1;
	background-color:#7ECEFD;
	padding:20px 0;
}
#moduleInput{
	grid-row:2/5;
	padding:50px;
	margin:0;
	background-color: #E5E5E5;
	display:grid;
	grid-template-columns: auto auto auto;
	grid-template-rows:1fr 1fr 1fr;
	grid-gap:30px;
	border-right:1px solid #DBDBDB;
	grid-template-areas:
	"i0 i1 i2"
	"i3 i4 i5"
	"i6 . .";
}
#moduleInput li{
	display:block;
	position:relative;
	list-style-type: none;
	font-size:12px;
	transition:0.3s;
}
#moduleInput .i0{
	grid-area: i0;
}
#moduleInput .i1{
	grid-area: i1;
}
#moduleInput .i2{
	grid-area: i2;
}
#moduleInput .i3{
	grid-area: i3;
}
#moduleInput .i4{
	grid-area: i4;
}
#moduleInput .i5{
	grid-area: i5;
}
#moduleInput .i6{
	grid-area: i6;
}

#moduleInput li:hover{
	transform: scale(1.1, 1.1);
	border-radius: 5px;
}
#moduleInput li img{
	border-radius: 5px;
	box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);
	transition:0.3s;
	cursor:pointer;
}
#moduleInput li img:hover{
	box-shadow: 0px 7px 5px 0 rgba(0, 0, 0, 0.3);
}
#moduleInput li span{
	color:#000;
	position:absolute;
	width:100%;
	bottom:6%;
	left:0;
}
.active{
	/* box-shadow:0 0 5px 2px #E4517B; */
}
#moduleOutput{
	grid-row:2 / 5;
	padding:50px;
	margin:0;
	background-color: #E5E5E5;
	display:grid;
	grid-template-columns: auto auto auto;
	grid-template-rows:1fr 1fr 1fr;
	grid-gap:30px;
	grid-template-areas:
	"o0 o1 o2"
	"o3 o4 o5"
	". . .";
}
#moduleOutput li{
	display:block;
	position:relative;
	list-style-type: none;
	font-size:12px;
	transition:0.3s;
}
#moduleOutput .o0{
	grid-area:o0 ;
}
#moduleOutput .o1{
	grid-area:o1;
}
#moduleOutput .o2{
	grid-area:o2;
}
#moduleOutput .o3{
	grid-area:o3;
}
#moduleOutput .o4{
	grid-area:o4;
}
#moduleOutput .o5{
	grid-area:o5;
}
#moduleOutput li:hover{
	transform: scale(1.1, 1.1);
	border-radius: 5px;
}
#moduleOutput li img{
	border-radius: 5px;
	box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);
	transition:0.3s;
	cursor:pointer;
}
#moduleOutput li img:hover{
	box-shadow: 0px 7px 5px 0 rgba(0, 0, 0, 0.3);
}
#moduleOutput li span{
	color:#000;
	position:absolute;
	width:100%;
	bottom:6%;
	left:0;
}
#reset {
	background-color: #000;
	color: #fff;
	border: none;
	cursor: pointer;
	padding: 10px 20px;
	float: right;
	margin-right: 10%;
	margin-top:-40px;
}

#reset:focus {
  outline: none;
}

.remove {
  position: absolute;
  top: 0;
  right: 0;
	z-index: 1;
	display: none;
	background-color: #000;
	color: #fff;
	border: none;
	opacity: 0.1;
	padding: 5px 10px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	cursor: pointer;
}

.remove:hover {
	opacity: 0.9;
	-webkit-transform: scale(1.1, 1.1);
	-ms-transform: scale(1.1, 1.1);
	transform: scale(1.1, 1.1);
}
@media screen and (max-width:1047px){
#moduleInput li span{
	top:100%;
	font-size:12px;
}
#moduleOutput li span{
	top:100%;
	font-size:12px;
}
}
@media screen and (max-width:639px){
#moduleInput{
	grid-row:2/5;
	padding:5px;
	margin:0;
	background-color: #E5E5E5;
	display:grid;
	grid-template-columns: auto auto auto;
	grid-template-rows:1fr 1fr 1fr;
	grid-gap:5px;
	border-right:1px solid #DBDBDB;
	grid-template-areas:
	"i0 i1 i2"
	"i3 i4 i5"
	"i6 . .";
}
#moduleInput li span{
	display:none;
}
#moduleOutput{
	grid-row:2 / 5;
	padding:5px;
	margin:0;
	background-color: #E5E5E5;
	display:grid;
	grid-template-columns: auto auto auto;
	grid-template-rows:1fr 1fr 1fr;
	grid-gap:5px;
	grid-template-areas:
	"o0 o1 o2"
	"o3 o4 o5"
	". . .";
}
#moduleOutput li span{
	display:none;
}
#selection{
	overflow: hidden;
}
.wrapper{
	overflow:hidden;
}






}
