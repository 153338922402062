.partner {
	display:flex;
	flex-wrap:wrap;
	justify-content: space-between;
}
.partner header{
	width:80%;
	margin:0 10%;
}
.partner header img{
	width:10%;
	padding-bottom:50px;
}
.partner header span{
	display:inline-block;
	width:100%;
	font-size:24px;
	color:#333;
}
.partner .partnerBody{
	width:80%;
	margin:0 auto;
	display:flex;
	flex-wrap:wrap;

}
.partner .partnerBody .partnerList{
	width:15%;
	margin:5%;
}
@media screen and (max-width:639px){
.partner header{
	width:100%;
	margin:0;
}
.partner header img{
	width:30%;
}
.partner .partnerBody .partnerList{
	width:40%;
	margin:10% 5%;
	margin-bottom:0;
}
}
