.downLoad{
    margin-top: 100px;
    background-color: #F0F0F0;
    padding-top: 50px;
    padding-bottom: 50px;
}
.downLoad p,h2{
    padding: 0;
    margin: 0;
}
.downLoad .titleName{
    margin: 80px 0 50px;
    font-weight: 600;
}
.downLoad .top{
    padding-top: 50px;
    padding-bottom: 60px;
}
.downLoad .top p{
    margin: 16px 0 12px;
}

.downLoad .down{
    width: 80%;
    margin: 0 auto;
    padding:2rem;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 0 2px #E6EBEE;
    position: relative;
    margin-bottom: 40px;
    border-radius: 5px;
}
.downLoad .down .downLeft{
    margin-right: 50px;
}
.downLoad .down .downLeft img{
    width: 190px;
}
.downLoad .down .downRight{
    width: calc(100% - 290px);
    text-align: left;
}
.downLoad .down .downRight .downName{
    font-size: 2rem;
    color: #000;
    font-weight: 600;
}
.downLoad .down .downRight .content{
    font-size: 1rem;
    margin-top: 25px;
}
.downLoad .down .downRight .setting{
    margin-top: 50px;
    margin-bottom: 5px;
    font-size: 1rem;
    color: #000;
    font-weight: 600;
}
.downLoad .down .downRight .downing{
    position: absolute;
    right: 40px;
    bottom: 35px;
}
.downLoad .down .downRight button{
    border: none;
    outline: none;
    padding: 10px 30px;
    background-color: #1EAAFF;
    color: #fff;
    font-size: 16px;
    border-radius: 30px;
    box-shadow: 0 2px 10px #C8E6F1;
    cursor: pointer;
}

@media screen and (max-width:1075px){
    .down .downing{
        /* display: none; */
    }
    .downLoad .down .downLeft{
        /* width: 30%; */
    }
    .downLoad .down .downLeft img{
        display: block;
        width: 90%;
    }
    .downLoad .down .downRight button{
        border: none;
        outline: none;
        padding: 5px 10px;
        background-color: #1EAAFF;
        color: #fff;
        font-size: 0.7rem;
        border-radius: 30px;
        box-shadow: 0 2px 10px #C8E6F1;
        cursor: pointer;
    }
    .downLoad .down .downRight .downName{
        font-size: 1.5rem;
    }
    .downLoad .down .downRight .content{
        font-size: 0.8rem;
        margin-top: 5px;
    }
    .downLoad .down .downRight .setting{
        margin-top: 20px;
        margin-bottom: 5px;
        font-size: 0.8rem;
        color: #000;
        font-weight: 600;
    }
    .downLoad .down .downLeft{
        margin-right: 30px;
    }
    
}
@media screen and (max-width:890px){
    .downing{
        display: none;
    }
    .downLoad .down{
        padding: 1rem;
    }
    .downLoad .down .first{
        /* padding-top: 40px; */
    }

    .downLoad .down .downLeft img{
        display: block;
        width: 100px;
    }
    .downLoad .down .downLeft{
        margin-right: 1rem;
    }

    .downLoad .down .downRight{
        width: 65%;
        text-align: left;
    }
    .downLoad .down .downRight .downName{
        font-size: 1rem;
    }
    .downLoad .down .downRight .content{
        font-size: 0.8rem;
        margin-top: 5px;
    }
    .downLoad .down .downRight .setting{
        margin-top: 20px;
        margin-bottom: 5px;
        font-size: 0.8rem;
        color: #000;
        font-weight: 600;
    }
}
