.OrderQuery{
  width:80%;
  margin:50px auto;
}
.OrderQuery__table{
  width:100%;
  border-collapse: collapse;
}
.OrderQuery__cell{
  border:2px solid #000;
}
.OrderQuery__cell td{
 padding:15px;
}
@media screen and (max-width:768px){
  .OrderQuery{
    width:100%;
    margin:50px auto;
    font-size:12px;
  }
}
